import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AccountPickerComponent} from '@app/components/shared/account-picker/index.component';
import {AccountSessionComponent} from '@app/components/shared/logs/account-session/account-session.component';
import {BrowserDetectionComponent} from './modal/browser-detection/browser-detection.component';
import {BitstreamService} from '@app/services/api/bitstream.service';
import {BitstreamTreeComponent} from '@app/components/shared/bitstream-tree/index.component';
import {ConfirmationComponent} from '@app/components/shared/modal/confirmation/index.component';
import {IndicatorComponent} from '@app/components/shared/indicator.component';
import {LoaderComponent} from '@app/components/shared/loader.component';
import {LivestampPipe} from '@app/pipe/livestamp.component';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {PaginationPipe} from '@app/pipe/pagination.pipe';
import {PromptComponent} from '@app/components/shared/modal/prompt/index.component';
import {StudyPickerComponent} from '@app/components/shared/study-picker/index.component';
import {TextModalComponent} from '@app/components/shared/modal/text/index.component';
import {StudyLogComponent} from '@app/components/shared/logs/study-log/study-log.component';
import {ViewerModalComponent} from '@app/components/shared/modal/viewer/viewer-modal.component';

import {PopoverModule} from 'ngx-bootstrap/popover';
import {StudyLogPipe} from '@app/pipe/study-log.pipe';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import { MaintenanceComponent } from './modal/maintenance/maintenance.component';

@NgModule({
	declarations: [
		AccountPickerComponent,
		BitstreamTreeComponent,
		BrowserDetectionComponent,
		ConfirmationComponent,
		IndicatorComponent,
		LivestampPipe,
		LoaderComponent,
		PaginationPipe,
		PromptComponent,
		TextModalComponent,
		StudyPickerComponent,
		ViewerModalComponent,
		AccountSessionComponent,
		StudyLogComponent,
		StudyLogPipe,
		MaintenanceComponent,
	],
	exports: [
		AccountPickerComponent,
		BitstreamTreeComponent,
		ConfirmationComponent,
		IndicatorComponent,
		LivestampPipe,
		LoaderComponent,
		PaginationPipe,
		TextModalComponent,
		StudyPickerComponent,
		ViewerModalComponent,
		AccountSessionComponent,
		StudyLogComponent,
		StudyLogPipe,
	],
	imports: [
		CommonModule,
		PopoverModule.forRoot(),
		ReactiveFormsModule,
		TooltipModule.forRoot(),
		TypeaheadModule.forRoot(),
		PaginationModule.forRoot(),
		FormsModule,
	],
	entryComponents: [
		BrowserDetectionComponent,
		ConfirmationComponent,
		PromptComponent,
		TextModalComponent,
		ViewerModalComponent,
	],
	providers: [BitstreamService],
})
export class SharedModule {}
