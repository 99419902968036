import {Injectable} from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	RouterStateSnapshot,
} from '@angular/router';

import {AuthenticationService} from '@app/services/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private authentication: AuthenticationService) {}

	public canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		if (!this.authentication.logged()) {
			this.authentication.logout();
			return false;
		}

		return true;
	}
}
