import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from './components/shared/shared.module';
import {DashboardModule} from './components/dashboard/dashboard.module';
import {AuthGuard} from './guard/auth.guard';
import {appRouting, appRoutingProviders} from './app.routing';
import {AppComponent} from './app.component';
import {LoginComponent} from './components/login/login.component';
import {AuthenticationService} from '@app/services/authentication.service';
import {StorageService} from '@app/services/storage.service';
import {BsModalService, ModalModule} from 'ngx-bootstrap/modal';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {GenderService} from '@app/services/api/gender.service';
import {EthnicityService} from '@app/services/api/ethnicity.service';
import {TagService} from '@app/services/api/tag.service';
import {TagTypeService} from '@app/services/api/tag-type.service';
import {DeviceService} from '@app/services/api/device.service';
import {SystemService} from '@app/services/api/system.service';
import {UserService} from '@app/services/api/user.service';
import {ViewService} from '@app/services/api/view.service';
import {NotificationService} from '@app/services/notification.service';
import {UserDeviceService} from '@app/services/api/user-device.service';
import {StudyUserService} from '@app/services/api/study-user.service';
import {StudyService} from '@app/services/api/study.service';
import {StudyZipRequestService} from '@app/services/api/study-zip-request.service';
import {ModalService} from '@app/services/ngx-bootstrap/modal-service.service';
import {AuthTokenService} from '@app/services/api/auth-token.service';
import {PasswordRequestService} from '@app/services/api/password-request.service';
import {PasswordRequestsComponent} from '@app/components/password-request/index.component';
import {PasswordRecoveryComponent} from '@app/components/password-recovery/index.component';
import {AddModalityModalComponent} from '@app/components/study/information/modal/index.component';
import {StudyDownloaderDocumentationComponent} from '@app/components/study/downloader/documentation/index.component';
import {NewsComponent} from '@app/components/news/index.component';
import {NoopInterceptor} from '@app/services/api/interceptors/noop.intercept';
import {AccountSessionService} from '@app/services/api/account-session.service';
import {StudyLogService} from '@app/services/api/study-log.service';
import {StudyLogPipe} from '@app/pipe/study-log.pipe';
import {ProductService} from '@app/services/api/product.service';
import {BitstreamProductService} from '@app/services/api/bitstream-product.service';
import {BitstreamMaskService} from "@app/services/api/bitstream-mask.service";
import {StudyAttachmentService} from "@app/services/api/study-attachment.service";
import {ModalityService} from "@app/services/api/modality.service";
import {SystemViewService} from "@app/services/api/system-view.service";
import {SystemModalityService} from "@app/services/api/system-modality.service";
import {DocumentCreationModalComponent} from '@app/components/admin/document/modal/document-creation-modal/document-creation-modal.component';
import {ToastrModule} from 'ngx-toastr';
import {HighchartsChartModule} from 'highcharts-angular';

@NgModule({
	bootstrap: [
		AppComponent
	],
	declarations: [
		AddModalityModalComponent,
		AppComponent,
		DocumentCreationModalComponent,
		LoginComponent,
		NewsComponent,
		PasswordRecoveryComponent,
		PasswordRequestsComponent,
		StudyDownloaderDocumentationComponent
	],
	entryComponents: [
		AddModalityModalComponent,
		DocumentCreationModalComponent,
		NewsComponent,
		StudyDownloaderDocumentationComponent
	],
	exports: [
		RouterModule,
		StudyLogPipe
	],
	imports: [
		appRouting,
		BrowserModule,
		BrowserAnimationsModule,
		DashboardModule,
		FormsModule,
		HighchartsChartModule,
		HttpClientModule,
		ModalModule.forRoot(),
		PaginationModule.forRoot(),
		ReactiveFormsModule,
		ToastrModule.forRoot(),
		SharedModule,
		TypeaheadModule.forRoot()
	],
	providers: [
		{provide: HTTP_INTERCEPTORS, useClass: NoopInterceptor, multi: true},
		AccountSessionService,
		AuthenticationService,
		AuthGuard,
		AuthTokenService,
		BitstreamMaskService,
		BitstreamProductService,
		DeviceService,
		EthnicityService,
		GenderService,
		ModalityService,
		NotificationService,
		PasswordRequestService,
		ProductService,
		StorageService,
		StudyLogService,
		StudyService,
		StudyAttachmentService,
		StudyUserService,
		StudyZipRequestService,
		SystemService,
		SystemModalityService,
		SystemViewService,
		TagService,
		TagTypeService,
		UserService,
		UserDeviceService,
		ViewService,

		appRoutingProviders,
		{
			provide: BsModalService,
			useClass: ModalService
		}
	]
})
export class AppModule {
}
