import {Component, EventEmitter, Output} from '@angular/core';

import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
	templateUrl: 'index.component.html',
})
export class ConfirmationComponent {
	@Output() onConfirm = new EventEmitter<void>();

	public unCancelable = false;
	public message = '';

	constructor(public modal: BsModalRef) {}

	public confirm(): void {
		this.onConfirm.emit();
		this.modal.hide();
	}
}
