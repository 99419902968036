import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from '@app/models/user.model';
import {UserDevice} from '@app/models/user-device.model';
import {Observable} from 'rxjs';

@Injectable()
export class UserService {
	constructor(private client: HttpClient) {}

	public get(): Observable<User[]> {
		return this.client
			.get<User[]>('/users')
			.pipe(
				map((response) =>
					response.map((user) => Object.assign(new User(), user))
				)
			);
	}

	public getByEmail(email: string): Observable<User[]> {
		return this.client
			.get<User[]>('/users?email=' + email)
			.pipe(
				map((response) =>
					response.map((user) => Object.assign(new User(), user))
				)
			);
	}

	public getById(id: number): Observable<User> {
		return this.client
			.get<User>('/users/' + id)
			.pipe(map((response) => Object.assign(new User(), response)));
	}

	public getDevices(user: User): Observable<User[]> {
		return this.client
			.get<User[]>('/users/' + user.id + '/devices')
			.pipe(
				map((response) =>
					response.map((user) => Object.assign(new User(), user))
				)
			);
	}

	public getUserDevices(id: number): Observable<UserDevice[]> {
		return this.client
			.get<UserDevice[]>('/users/' + id + '/user-devices')
			.pipe(
				map((response) =>
					response.map((userDevice) =>
						Object.assign(new UserDevice(), userDevice)
					)
				)
			);
	}

	public post(user: User): Observable<User> {
		return this.client
			.post<User>('/users', {
				email: user.email,
				firstName: user.firstName,
				lastName: user.lastName,
				username: user.username,
			})
			.pipe(map((response) => Object.assign(new User(), response)));
	}

	public put(user: User): Observable<User> {
		return this.client
			.put<User>('/users/' + user.id, {
				company: user.company,
				currency: user.currency,
				demo: user.demo,
				email: user.email,
				firstName: user.firstName,
				lastName: user.lastName,
				level: user.level,
				phoneNumber: user.phoneNumber,
				premium: user.premium,
				status: user.status,
				username: user.username,
			})
			.pipe(map((response) => Object.assign(new User(), response)));
	}
}
