import {Account} from '@app/models/account.model';
import {Device} from '@app/models/device.model';
import {Guest} from '@app/models/guest.model';
import {Study} from '@app/models/study.model';
import {User} from '@app/models/user.model';

export class StudyUser {
	public active: boolean;
	public id: number;
	public level: number;
	public study: Study;
	public user: Account | Device | Guest | User;

	get isAdmin(): boolean {
		return (this.level & 1) === 1;
	}

	get isRegionEditor(): boolean {
		return (this.level & 16) === 16;
	}

	get isScaleManager(): boolean {
		return (this.level & 8) === 8;
	}

	get isUploader(): boolean {
		return (this.level & 4) === 4;
	}

	set isAdmin(isScaleManager: boolean) {
		if (isScaleManager) {
			this.level |= 1;
		} else {
			this.level ^= 1;
		}
	}

	set isRegionEditor(isRegionEditor: boolean) {
		if (isRegionEditor) {
			this.level |= 16;
		} else {
			this.level ^= 16;
		}
	}

	set isScaleManager(isScaleManager: boolean) {
		if (isScaleManager) {
			this.level |= 8;
		} else {
			this.level ^= 8;
		}
	}

	set isUploader(isUploader: boolean) {
		if (isUploader) {
			this.level |= 4;
		} else {
			this.level ^= 4;
		}
	}
}
