import {Study} from '@app/models/study.model';

export class Visit {
	public duration: number;
	public id: number;
	public name: string;
	public rank: number;
	public study: Study;
	public type: number;
}
