<form [formGroup]="form" (submit)="submit()">
	<div class="input-group">
		<ng-container>
			<ng-template #customItemTemplate let-model="item" let-index="index">
				<div>{{ model.name }}</div>
			</ng-template>

			<input formControlName="needle" [typeahead]="getStudySource()" [typeaheadItemTemplate]="customItemTemplate" typeaheadOptionField="name" [typeaheadSingleWords]="true" [typeaheadMinLength]="0" [typeaheadScrollable]="true" autocomplete="off" class="form-control">
		</ng-container>

		<span class="input-group-btn">
		<button class="btn btn-primary" type="submit" [disabled]="!form.valid"><ng-content></ng-content></button>
	</span>
	</div>
</form>
