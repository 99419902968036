import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MeasurementBitstream} from '@app/models/measurement-bitstream.model';
import {Observable} from 'rxjs';

@Injectable()
export class MeasurementBitstreamService {
	constructor(private client: HttpClient) {}

	public delete(measurementBitstream: MeasurementBitstream) {
		return this.client.delete(
			'/measurement-bitstreams/' + measurementBitstream.id
		);
	}

	public get(params: any): Observable<MeasurementBitstream[]> {
		const queries = [];
		if (params.date.from) queries.push('uploadAt[after]=' + params.date.from);
		if (params.date.to) queries.push('uploadAt[before]=' + params.date.to);
		if (params.limit) queries.push('itemsPerPage=' + params.limit);
		if (params.device) queries.push('uploadBy.id=' + params.device);

		return this.client
			.get<MeasurementBitstream[]>(
				'/measurement-bitstreams?' + queries.join('&')
			)
			.pipe(
				map((response) =>
					response.map((measurementBitstream) =>
						Object.assign(new MeasurementBitstream(), measurementBitstream)
					)
				)
			);
	}
}
