import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'pagination',
})
export class PaginationPipe implements PipeTransform {
	public static itemsPerPage = 10;

	public transform<T>(
		objects: T[],
		page: number,
		offset = PaginationPipe.itemsPerPage
	): T[] {
		const start = offset * page - offset;
		return objects.slice(start, start + offset);
	}
}
