import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Product} from '@app/models/product.model';
import {Observable} from 'rxjs';

@Injectable()
export class ProductService {
	constructor(private client: HttpClient) {}

	public post(product: Product): Observable<Product> {
		return this.client
			.post<Product>('/products', {
				name: product.name,
				study: {
					id: product.study.id,
				},
			})
			.pipe(map((response) => Object.assign(new Product(), response)));
	}
}
