import {BitstreamMask} from '@app/models/bitstream-mask.model';
import {Measurement} from '@app/models/measurement.model';
import {MeasurementBitstream} from '@app/models/measurement-bitstream.model';
import {Patch} from '@app/models/patch.model';
import {Pretreatment} from '@app/models/pretreatment.model';
import {Product} from '@app/models/product.model';
import {Tag} from '@app/models/tag.model';
import {environment} from '@env/environment';

export class Bitstream {
	public checksum: string;
	public date: string;
	public id: number;
	public filename: string;
	public originalUrl: string;
	public sizeBytes: number;
	public thumbnailUrl: string;

	public bitstreamMasks: BitstreamMask[] = [];
	public measurement: Measurement;
	public measurementBitstream: MeasurementBitstream;
	public patches: Patch[] = [];
	public pretreatments: Pretreatment[] = [];
	public products: Product[] = [];
	public tags: Tag[] = [];

	get url(): string {
		return environment.url.apiv2 + '/bitstreams/' + this.id + '/thumbnail';
	}

	get concatenedTags(): string {
		if (this.tags.length) {
			return this.tags.map(tag => tag.name).join(' | ');
		}

		return '';
	}
}
