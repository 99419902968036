import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Bitstream} from '@app/models/bitstream.model';
import {Device} from '@app/models/device.model';
import {DeviceReport} from '@app/models/device-report.model';
import {Patch} from '@app/models/patch.model';
import {UserDevice} from '@app/models/user-device.model';
import {DeviceStatistic} from '@app/models/device-statistic.model';
import {Observable} from 'rxjs';

@Injectable()
export class DeviceService {
	constructor(private client: HttpClient) {}

	public get(): Observable<Device[]> {
		return this.client
			.get<Device[]>('/devices')
			.pipe(
				map((response) =>
					response.map((device) => Object.assign(new Device(), device))
				)
			);
	}

	public getById(id: number): Observable<Device> {
		return this.client
			.get<Device>('/devices/' + id)
			.pipe(map((response) => Object.assign(new Device(), response)));
	}

	public getBitstreamsById(id: number): Observable<Bitstream[]> {
		return this.client
			.get<Bitstream[]>('/devices/' + id + '/bitstreams')
			.pipe(
				map((response) =>
					response.map((bitstream) => Object.assign(new Bitstream(), bitstream))
				)
			);
	}

	public getDeviceReportById(id: number): Observable<DeviceReport[]> {
		return this.client
			.get<DeviceReport[]>('/devices/' + id + '/device-reports')
			.pipe(
				map((response) =>
					response.map((deviceReport) =>
						Object.assign(new DeviceReport(), deviceReport)
					)
				)
			);
	}

	public getDeviceStatisticsById(id: number): Observable<DeviceStatistic[]> {
		return this.client
			.get<DeviceStatistic[]>('/devices/' + id + '/device-statistics')
			.pipe(
				map((response) =>
					response.map((deviceStatistic) =>
						Object.assign(new DeviceStatistic(), deviceStatistic)
					)
				)
			);
	}

	public getPatchesById(
		id: number,
		position: number,
		modality: number,
		view: number
	): Observable<Patch[]> {
		return this.client
			.get<Patch[]>(
				'/devices/' +
					id +
					'/patches?position=' +
					position +
					'&measurementBitstream.measurement.modality.id=' +
					modality +
					'&measurementBitstream.measurement.view.id=' +
					view
			)
			.pipe(
				map((response) =>
					response.map((patch) => Object.assign(new Patch(), patch))
				)
			);
	}

	public getUserDevicesById(id: number): Observable<UserDevice[]> {
		return this.client
			.get<UserDevice[]>('/devices/' + id + '/user-devices')
			.pipe(
				map((response) =>
					response.map((userDevice) =>
						Object.assign(new UserDevice(), userDevice)
					)
				)
			);
	}

	public post(device: Device): Observable<Device> {
		return this.client
			.post<Device>('/devices', {
				serial: device.serial,
				system: {
					id: device.system.id,
				},
			})
			.pipe(map((response) => Object.assign(new Device(), response)));
	}

	public put(device: Device): Observable<Device> {
		return this.client
			.put<Device>('/devices/' + device.id, {
				customer: device.customer,
				description: device.description,
			})
			.pipe(map((response) => Object.assign(new Device(), response)));
	}
}
