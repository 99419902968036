import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {AdminGuard} from '@app/guard/admin.guard';
import {DashboardComponent} from '@app/components/dashboard/dashboard.component';
import {dashboardRoutes} from '@app/components/dashboard/dashboard.routing';
import {ModalService} from '@app/services/ngx-bootstrap/modal-service.service';
import {SharedModule} from '@app/components/shared/shared.module';
import {TechnicianGuard} from '@app/guard/technician.guard';

@NgModule({
	declarations: [DashboardComponent],
	exports: [RouterModule],
	imports: [
		CommonModule,
		RouterModule.forChild(dashboardRoutes),
		SharedModule
	],
	providers: [AdminGuard, ModalService, TechnicianGuard],
})
export class DashboardModule {}
