import {PretreatmentArea} from '@app/models/pretreatment-area.model';
import {Measurement} from '@app/models/measurement.model';
import {PretreatmentType} from '@app/models/pretreatment-type.model';
import {Bitstream} from '@app/models/bitstream.model';

export class Pretreatment {
	public area: PretreatmentArea;
	public bitstream: Bitstream;
	public id: number;
	public measurement: Measurement;
	public parent: Bitstream;
	public type: PretreatmentType;
}
