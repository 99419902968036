import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {StudyZipRequest} from '@app/models/study-zip-request.model';
import {Observable} from 'rxjs';

@Injectable()
export class StudyZipRequestService {
	constructor(private client: HttpClient) {}

	public post(studyZipRequest: StudyZipRequest): Observable<StudyZipRequest> {
		return this.client
			.post<StudyZipRequest>('/study-zip-requests', {
				bitstreams: studyZipRequest.bitstreams,
				study: {
					id: studyZipRequest.study.id,
				},
			})
			.pipe(map((response) => Object.assign(new StudyZipRequest(), response)));
	}
}
