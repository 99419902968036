import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AccountSession} from '@app/models/account-session.model';
import {Observable} from 'rxjs';

@Injectable()
export class AccountSessionService {
	constructor(private client: HttpClient) {}

	public get(): Observable<AccountSession[]> {
		return this.client
			.get<AccountSession[]>('/account-sessions')
			.pipe(
				map((response) =>
					response.map((accountSession) =>
						Object.assign(new AccountSession(), accountSession)
					)
				)
			);
	}
}
