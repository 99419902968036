import {FormControl, FormGroup, Validators} from '@angular/forms';

export class LogGroup extends FormGroup {
	public from: FormControl;
	public to: FormControl;

	constructor() {
		const from = new Date();
		from.setDate(from.getDate() - 7);

		const to = new Date();
		to.setDate(to.getDate() + 1);

		const controls = {
			from: new FormControl(
				from.toISOString().split('T')[0],
				Validators.required
			),
			to: new FormControl(to.toISOString().split('T')[0], Validators.required),
		};

		super(controls, null, null);

		this.from = controls.from;
		this.to = controls.to;
	}
}

export class StudyLogGroup extends LogGroup {
	public action: FormControl;

	constructor() {
		const controls = {
			action: new FormControl(''),
		};

		super();
		this.action = controls.action;
		super.addControl('action', this.action);
	}
}
