import {AuthGuard} from '@app/guard/auth.guard';
import {DashboardComponent} from '@app/components/dashboard/dashboard.component';

export const dashboardRoutes = [
	{
		path: '',
		canActivate: [AuthGuard],
		component: DashboardComponent,
		children: [
			{path: '', redirectTo: 'studies', pathMatch: 'full'},
			{
				path: 'studies',
				loadChildren: '../study/study.module#StudyModule',
				canActivate: [AuthGuard],
			},
			{path: '', loadChildren: '../admin/admin.module#AdminModule'},
		],
	},
];
