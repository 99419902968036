<div class="form-group" style="display:flex">
  <div class="input-group" style="flex-grow:1">
    <div class="input-group-addon">
      <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
    </div>
    <input type="text" class="form-control" name="search" autocomplete="off" [(ngModel)]="needle">
  </div>
</div>

<form [formGroup]="form" (submit)="filter()">
  <div class="form-group">
    <label for="from">Action date</label>
    <div class="row">
      <div class="col-xs-6">
        <input type="date" class="form-control" id="from" formControlName="from">
      </div>

      <div class="col-xs-6">
        <input type="date" class="form-control" formControlName="to">
      </div>
    </div>
  </div>

  <div class="form-group">
    <label for="action">Actions</label>
    <div class="row">
      <div class="col-xs-6">
        <select class="form-control" id="action" formControlName="action">
          <option [value]="''"></option>
          <option [value]="'access'">Access</option>
        </select>
      </div>
    </div>
  </div>

  <div class="form-group">
    <div class="text-center">
      <button type="submit" class="btn btn-default">Search</button>
      <button type="button" class="btn btn-danger" (click)="reset()">Reset</button>
    </div>
    <button type="button" class="btn btn-primary pull-right" (click)="export()">Export</button>
  </div>
</form>

<ng-container *ngIf="actualstudyLogs | studyLog : {title: needle} as results">
  <ng-container [ngSwitch]="results.length > 0">
    <ng-container *ngSwitchCase="true">
      <table class="table table-striped">
        <thead>
        <tr class="text-center">
          <th>User</th>
          <th>Study</th>
          <th>Action</th>
          <th>
            Action date
            <span style="cursor: pointer; margin-left: 2em"
                  (click)="toggleSortLogsByDate()"
                  [ngSwitch]="chronologicSort">
                    <sub *ngSwitchCase="true" class='glyphicon glyphicon-triangle-bottom' aria-hidden='true'></sub>
                    <sup *ngSwitchCase="false" class='glyphicon glyphicon-triangle-top' aria-hidden='true'></sup>
                </span>
          </th>
        </tr>
        </thead>

        <tbody>
        <tr *ngFor="let actualstudyLog of results | pagination : page : itemsPerPage">
          <td>{{actualstudyLog.account.firstName}} {{actualstudyLog.account.lastName}}</td>
          <td>{{actualstudyLog.study.name}}</td>
          <td>{{actualstudyLog.action}}</td>
          <td>{{actualstudyLog.createdAt | date:'yyyy/MM/dd HH:mm:ss'}}</td>
        </tr>
        </tbody>
      </table>
      <div class="text-center">
        <pagination class="pagination-sm"
                    [boundaryLinks]="true"
                    [totalItems]="results.length"
                    [itemsPerPage]="itemsPerPage"
                    [rotate]="true" [maxSize]="10"
                    [(ngModel)]="page" previousText="&lsaquo;"
                    nextText="&rsaquo;" firstText="&laquo;"
                    lastText="&raquo;"></pagination>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="false">
      <div class="text-center" style="margin-top: 10%; margin-bottom: 10%">
        <em>No logs found</em>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

