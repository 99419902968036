import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SystemView} from '@app/models/system-view.model';
import {Observable} from 'rxjs/Observable';
import {map} from 'rxjs/operators';

@Injectable()
export class SystemViewService {
	constructor(private client: HttpClient) {}

	public get(): Observable<SystemView[]> {
		return this.client
			.get<SystemView[]>('/system-views')
			.pipe(
				map((response) =>
					response.map((systemView) =>
						Object.assign(new SystemView(), systemView)
					)
				)
			);
	}
}
