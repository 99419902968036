import {Injectable} from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	RouterStateSnapshot,
} from '@angular/router';
import {AuthenticationService} from '@app/services/authentication.service';
import {StorageService} from '@app/services/storage.service';

@Injectable()
export class TechnicianGuard implements CanActivate {
	constructor(
		private authentication: AuthenticationService,
		private storageService: StorageService
	) {}

	public canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		if (!this.authentication.logged()) {
			this.authentication.logout();
			return false;
		}

		return this.storageService.isTechnician();
	}
}
