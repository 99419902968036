import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TagType} from '@app/models/tag-type.model';
import {Observable} from 'rxjs';

@Injectable()
export class TagTypeService {
	constructor(private client: HttpClient) {}

	public get(): Observable<TagType[]> {
		return this.client
			.get<TagType[]>('/tag-types')
			.pipe(
				map((response) =>
					response.map((tagType) => Object.assign(new TagType(), tagType))
				)
			);
	}

	public post(tagType: TagType): Observable<TagType> {
		return this.client
			.post<TagType>('/tag-types', {name: tagType.name})
			.pipe(map((response) => Object.assign(new TagType(), response)));
	}
}
