import {Device} from '@app/models/device.model';
import {Ethnicity} from '@app/models/ethnicity.model';
import {Gender} from '@app/models/gender.model';
import {Measurement} from '@app/models/measurement.model';
import {User} from '@app/models/user.model';
import {Visit} from '@app/models/visit.model';
import {Study} from '@app/models/study.model';

export class StudyPatient {
	public age: number;
	public code: string;
	public createdBy: Device | User;
	public ethnicity: Ethnicity;
	public gender: Gender;
	public id: number;
	public photoType: number;
	public status: number;
	public study: Study;
	public test: boolean;

	public measurements: Measurement[] = [];
	public visits: Visit[] = [];
}
