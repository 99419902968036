import {Account} from '@app/models/account.model';

export class User extends Account {
	public company: string;
	public currency: number;
	public demo: boolean;
	public email: string;
	public firstName: string;
	public ht: boolean;
	public lastName: string;
	public level: number;
	public phoneNumber: string;
	public premium: boolean;
	public username: string;

	public get name(): string {
		return this.firstName + ' ' + this.lastName.toUpperCase();
	}
}
