import {Component, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {NotificationService} from '@app/services/notification.service';
import {StorageService} from '@app/services/storage.service';
import {Cookie} from '@app/utils/cookie.util';
import {environment} from '@env/environment';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html'
})
export class AppComponent {
	constructor(
		notificationservice: NotificationService,
		router: Router,
		storageService: StorageService,
		vcr: ViewContainerRef
	) {
		if (!storageService.getToken()) {
			const authToken = Cookie.get(environment.cookie.authToken);

			if (authToken) {
				storageService.setAuthToken(JSON.parse(authToken));
				router.navigate(['']);
			}
		}
	}
}
