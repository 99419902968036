<div class="modal-header">
  <h4 class="modal-title pull-left">Add a new document</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="clearfix modal-body">
  <form [formGroup]="form" (submit)="submit()">
    <div class="form-group">
      <label class="font-weight-bold" for="name">Document name *</label>
      <input
          type="text" class="form-control"
          id="name" formControlName="name"
          autocomplete="off" maxlength="64"
          [class.is-invalid]="form.get('name').dirty && !form.get('name').valid">
      <label class="font-weight-bold" for="description" style="margin-top: 2rem">Description</label>
      <textarea
          class="form-control" rows="3"
          id="description" formControlName="description"
          autocomplete="off" maxlength="255"
          style="resize: none"
          [class.is-invalid]="form.get('description').dirty && !form.get('description').valid"></textarea>
      <div class="row" style="margin-bottom: 2rem; margin-top: 2.5rem">
        <div class="col-sm-6">
          <label class="font-weight-bold" for="category">Category *</label>
          <select
              class="form-control" id="category" formControlName="tag"
              [class.is-invalid]="form.get('tag').dirty && !form.get('tag').valid">
            <option *ngFor="let tag of tags" [value]="tag.id">{{tag.name}}</option>
          </select>
        </div>
        <div class="col-sm-6">
          <label class="font-weight-bold" for="software">Software</label>
          <select
              class="form-control" id="software" formControlName="software"
              [class.is-invalid]="form.get('software').dirty && !form.get('software').valid">
            <option [value]="null"></option>
            <option *ngFor="let software of softwares" [value]="software.id">{{software.name}}</option>
          </select>
        </div>
      </div>
    </div>
    <div>
      <small class="pull-left">* is required</small>
      <button type="submit" class="btn btn-dark pull-right" [disabled]="!form.valid">Add</button>
    </div>
  </form>
</div>
