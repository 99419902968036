import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {View} from '@app/models/view.model';
import {Observable} from 'rxjs';

@Injectable()
export class ViewService {
	constructor(private client: HttpClient) {}

	public get(): Observable<View[]> {
		return this.client
			.get<View[]>('/views')
			.pipe(
				map((response) =>
					response.map((view) => Object.assign(new View(), view))
				)
			);
	}
}
