import {Injectable} from '@angular/core';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpHeaders,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';

import {environment} from '@env/environment';

import {AuthenticationService} from '@app/services/authentication.service';
import {MaintenanceComponent} from '@app/components/shared/modal/maintenance/maintenance.component';
import {StorageService} from '@app/services/storage.service';

import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {ModalService} from '@app/services/ngx-bootstrap/modal-service.service';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class NoopInterceptor implements HttpInterceptor {
	constructor(
		private authenticationService: AuthenticationService,
		private modalService: ModalService,
		private storageService: StorageService
	) {}

	public intercept<T>(
		req: HttpRequest<T>,
		next: HttpHandler
	): Observable<HttpEvent<T>> {
		let headers = new HttpHeaders();
		headers = headers.append('X-Api-Version', '2');

		if (this.storageService.getToken()) {
			const authToken = this.storageService.getAuthToken();
			if (!authToken.createdAt) authToken.createdAt = new Date().toISOString();
			this.storageService.setAuthToken(authToken);

			headers = headers.append(
				'X-Auth-Token',
				this.storageService.getAuthToken().value
			);
		}

		const request = req.clone({
			url: environment.url.apiv2 + req.url,
			headers: headers,
		});

		return next.handle(request).pipe(
			catchError((error) => {
				if (error instanceof HttpErrorResponse) {
					// if (0 === error.status) {
					// 	this.authenticationService.logout();
					// 	this.modalService.show(
					// 		MaintenanceComponent,
					// 		{class: 'modal-lg'}
					// 	);
					// }
					if (401 === error.status) {
						this.authenticationService.logout();
					}
					if (503 === error.status) {
						this.authenticationService.logout();
						this.modalService.show(
							MaintenanceComponent,
							{class: 'modal-lg'}
						);
					}
				}

				throw error;
			})
		);
	}
}
