import {Account} from '@app/models/account.model';

/**
 * Todo: Implement abstracts methods
 */
export class Guest extends Account {
	public password: string;
	public prefix: string;
	public username: string;

	// public getUsername(): string {
	// 	if (this.prefix) {
	// 		return this.prefix + '_' + this.username;
	// 	}
	//
	// 	return this.username;
	// }
	//
	// public get fullName(): string {
	// 	return this.username.substring(this.username.indexOf('_') + 1);
	// }
}
