import {Bitstream} from '@app/models/bitstream.model';
import {Device} from '@app/models/device.model';

interface Attachment {
	bitstream: Bitstream;
	json?: Object;
}

interface Report {
	attachments: Attachment[];
	date: string;
	json?: Object;
	name: string;
	type: number;
}

export class DeviceReport {
	public report: Report;
	public createdAt: string;
	public device: Device;
	public id: number;
}
