import {Component, EventEmitter, OnInit, Output} from '@angular/core';

import {BsModalRef} from 'ngx-bootstrap/modal';
import {FormBuilder, FormGroup, ValidatorFn} from '@angular/forms';

@Component({
	templateUrl: 'index.component.html',
})
export class PromptComponent implements OnInit {
	@Output() onSubmit = new EventEmitter<string>();

	public button: string;
	public form: FormGroup;
	public label: string;
	public title: string;
	private validators: ValidatorFn | ValidatorFn[] | null;
	public value: string;

	constructor(formBuilder: FormBuilder, public modal: BsModalRef) {
		this.form = formBuilder.group({
			value: '',
		});
	}

	public ngOnInit(): void {
		const field = this.form.get('value');
		field.patchValue(this.value);
		field.setValidators(this.validators);
	}

	public submit(): void {
		this.onSubmit.emit(this.form.getRawValue().value);
		this.modal.hide();
	}
}
