import {Pipe, PipeTransform} from '@angular/core';
import {StudyLog} from '@app/models/study-log';

@Pipe({
	name: 'studyLog',
})
export class StudyLogPipe implements PipeTransform {
	public transform(studyLogs: StudyLog[], args: {title: string}): StudyLog[] {
		const text = args.title.toLowerCase();
		return studyLogs.filter(
			(studyLog) =>
				studyLog.study && studyLog.study.name.toLowerCase().includes(text)
		);
	}
}
