import {Modality} from '@app/models/modality.model';
import {Pretreatment} from '@app/models/pretreatment.model';
import {StudyPatient} from '@app/models/study-patient.model';
import {System} from '@app/models/system.model';
import {Visit} from '@app/models/visit.model';
import {Bitstream} from '@app/models/bitstream.model';
import {View} from '@app/models/view.model';
import {MeasurementBitstream} from '@app/models/measurement-bitstream.model';

export class Measurement {
	public id: number;
	public modality: Modality;
	public status: number;
	public studyPatient: StudyPatient;
	public system: System;
	public view: View;
	public visit: Visit;
	public zoneAlias: string;

	public bitstreams: Bitstream[] = [];
	public measurementBitstreams: MeasurementBitstream[] = [];
	public pretreatments: Pretreatment[] = [];

	get lastBitstream(): Bitstream {
		return this.bitstreams[this.bitstreams.length - 1];
	}

	get lastBitstreamUrl(): string {
		const bitstream = this.lastBitstream;
		return bitstream ? bitstream.url : '';
	}
}
