import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent} from '@angular/common/http';
import {Patch} from '@app/models/patch.model';
import {Observable} from 'rxjs';

@Injectable()
export class BitstreamService {
	constructor(private client: HttpClient) {}

	public downloadById(id: number): Observable<HttpEvent<Blob>> {
		return this.client.get<Blob>('/bitstreams/' + id + '/blob', {
			responseType: 'blob',
		} as any);
	}

	public getPatchesById(id: number): Observable<Patch[]> {
		return this.client
			.get<Patch[]>('/bitstreams/' + id + '/patches')
			.pipe(
				map((response) =>
					response.map((json) => Object.assign(new Patch(), json))
				)
			);
	}
}
