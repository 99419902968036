<div class="modal-header">
	<h4 class="modal-title">News</h4>
</div>

<div class="modal-body text-center">
	<div class="row">
		<div class="col-sm-3" style="height: 175px">
			<img src="./assets/softwares/studies-downloader/logo.png" alt="StudiesDownloader" style="max-height: 100%">
		</div>

		<div class="col-sm-9" style="display: flex;align-items: center;height: 175px">
			<div>
				<div class="form-group">
					A new software has been released to let you easily download images from your studies.<br />
					To get more info please click <a type="button" href="#" (click)="getSoftware(); false">here</a> or click "StudiesDownloader" in the drop down menu under your name at the top right hand corner.
				</div>

				<button type="button" class="btn btn-primary" (click)="modal.hide()">OK</button>
			</div>
		</div>
	</div>
</div>
