import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {StorageService} from '@app/services/storage.service';
import {AuthTokenService} from '@app/services/api/auth-token.service';
import {Cookie} from '@app/utils/cookie.util';
import {environment} from '@env/environment';

import {NewsComponent} from '@app/components/news/index.component';
import {BsModalService} from 'ngx-bootstrap/modal';

import {BrowserDetectionComponent} from '@app/components/shared/modal/browser-detection/browser-detection.component';
import {ToastrService} from 'ngx-toastr';

@Component({
	templateUrl: 'login.component.html',
	styleUrls: ['login.component.scss'],
})
export class LoginComponent {
	public environnement = environment;
	public form: FormGroup;
	public lock = false;

	constructor(
		private authTokenService: AuthTokenService,
		private formBuilder: FormBuilder,
		private modalService: BsModalService,
		private router: Router,
		private storageService: StorageService,
		private toastr: ToastrService
	) {
		this.form = this.formBuilder.group({
			password: ['', Validators.required],
			username: ['', Validators.required],
		});
	}

	public submit(): void {
		this.lock = true;
		this.form.disable();

		this.authTokenService
			.post(this.form.getRawValue())
			.subscribe(
				(authToken) => {
					document.cookie =
						environment.cookie.authToken +
						'=' +
						JSON.stringify(authToken) +
						';domain=newtone.fr;secure;SameSite=Strict';
					this.storageService.setAuthToken(authToken);
					this.router.navigateByUrl('studies').then(() => {
						this.toastr.success('Welcome');

						if (!Cookie.get('studyDownloaderNews')) {
							const date = new Date('2021-01-01T00:00:00');
							document.cookie =
								'studyDownloaderNews=1; expires=' +
								date.toUTCString() +
								'; path=/';

							this.modalService.show(NewsComponent, {class: 'modal-lg'});
						}

						// Or use bowser
						if (window.navigator.userAgent.indexOf('Chrome') === -1) {
							this.modalService.show(BrowserDetectionComponent, {
								class: 'modal-lg',
							});
						}
					});
				},
				error => {
					Cookie.remove(environment.cookie.authToken);
					localStorage.clear();
					if (400 === error.status) {
						this.toastr.error('Invalid login or password');
					}
				}
			)
			.add(() => {
				this.form.enable();
				this.lock = false;
			});
	}
}
