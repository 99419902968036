import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthToken} from '@app/models/auth-token.model';
import {Credentials} from '@app/models/credentials.model';
import {Observable} from 'rxjs';

@Injectable()
export class AuthTokenService {
	constructor(private client: HttpClient) {}

	public delete(authToken: AuthToken) {
		return this.client.delete('/auth-tokens/' + authToken.id);
	}

	public post(credentials: Credentials): Observable<AuthToken> {
		return this.client
			.post<AuthToken>('/credentials', {
				password: credentials.password,
				username: credentials.username,
			})
			.pipe(map((response) => Object.assign(new AuthToken(), response)));
	}
}
