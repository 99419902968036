import {Modality} from '@app/models/modality.model';
import {View} from '@app/models/view.model';

export class System {
	public dollar: string;
	public euro: string;
	public id: number;
	public manufacturer: string;
	public name: string;
	public version: string;

	public modalities: Modality[] = [];
	public views: View[] = [];
}
