<div class="modal-header">
  <h4 class="modal-title">Browser recommendation</h4>
</div>

<div class="modal-body text-center">
  <div class="row">
    <div class="col-sm-2" style="height: 115px">
      <img src="./assets/img/google_chrome_logo.png" alt="GoogleChromeLogo" style="max-height: 100%">
    </div>

    <div class="col-sm-10" style="display: flex;align-items: center;height: 150px">
      <div>
        <div>
          To get the most of the Cipaas web application's suite, we recommend to use Google Chrome as browser. <br>
          To get Google Chrome, please go to <a href="https://www.google.com/intl/en_en/chrome/" target="_blank">official web site</a>
        </div>

        <button type="button" class="btn btn-primary pull-right" (click)="modal.hide()">OK</button>
      </div>
    </div>
  </div>
</div>
