import {Injectable} from '@angular/core';

@Injectable()
export class NotificationService {
	private notification = false;

	constructor() {
		if (!('Notification' in window)) {
			return;
		}

		Notification.requestPermission((permission) => {
			this.notification = permission === 'granted';
		});
	}

	public push(title: string, options?) {
		if (this.notification) {
			new Notification(title, options);
		}
	}
}
