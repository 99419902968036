<div>
  <nav class="navbar navbar-default navbar-fixed-top">
    <div class="container">
      <div class="navbar-header">
        <a class="navbar-brand" routerLink="/">Newtone</a>
      </div>
    </div>
  </nav>

  <div class="container" style="padding-top : 80px ;">
    <div class="alert alert-info row" role="alert">
      <b>Note:</b> Your password must have at least <b>one lowercase</b>, <b>one uppercase</b>, <b>one number</b> and <b>eight characters</b>
    </div>

    <form class="form-horizontal" [formGroup]="form" (submit)="submit()">
      <div class="form-group">
        <label class="font-weight-bold" for="password">New password*</label>
        <input type="password" class="form-control" id="password" autocomplete="off" formControlName="password" [class.is-invalid]="form.password.value && !form.password.valid">
        <small *ngIf="form.password.value && !form.password.valid" class="form-text text-danger">Invalid password</small>
      </div>

      <div class="form-group">
        <label class="font-weight-bold" for="confirmation">New password confirmation*</label>
        <input type="password" class="form-control" id="confirmation" autocomplete="off" formControlName="confirmation" [class.is-invalid]="(form.confirmation.value && !form.confirmation.valid)">
        <small *ngIf="form.confirmation.value && !form.confirmation.valid" class="form-text text-danger">Invalid confirmation</small>
      </div>

      <p class="text-center">
        <button class="btn btn-dark" type="submit" [disabled]="!form.valid">Submit</button>
      </p>
    </form>
  </div>
</div>
