import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Modality} from '@app/models/modality.model';
import {Observable} from 'rxjs/Observable';
import {map} from 'rxjs/operators';

@Injectable()
export class ModalityService {
	constructor(private client: HttpClient) {}

	public get(): Observable<Modality[]> {
		return this.client
			.get<Modality[]>('/modalities')
			.pipe(
				map((response) =>
					response.map((modality) => Object.assign(new Modality(), modality))
				)
			);
	}
}
