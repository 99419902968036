import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {StorageService} from '@app/services/storage.service';

@Injectable()
export class AuthenticationService {
	public static isTokenExpired(): boolean {
		const visit = Date.now() - parseInt(localStorage.getItem('timestamp'), 10);
		return visit > 3600000;
	}

	public static setTimestamp(): void {
		localStorage.setItem('timestamp', Date.now().toString());
	}

	constructor(private router: Router, private storageService: StorageService) {}

	public logout(): void {
		localStorage.clear();
		this.router.navigate(['login']);
	}

	public logged(): boolean {
		return (
			this.storageService.getToken() && !AuthenticationService.isTokenExpired()
		);
	}
}
