import {Component, OnDestroy} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {PasswordRequestService} from '@app/services/api/password-request.service';
import {PasswordRequest} from '@app/models/password-request.model';

import {Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';

@Component({
	templateUrl: 'index.component.html',
})
export class PasswordRequestsComponent implements OnDestroy {
	public form = new PasswordFormGroup();
	public loaded = false;
	private subscription: Subscription;

	constructor(
		private passwordRequestService: PasswordRequestService,
		private router: Router,
		private toastr: ToastrService
	) {}

	public ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	public submit(): void {
		const passwordRequest = new PasswordRequest();
		passwordRequest.email = this.form.email.value;

		this.subscription = this.passwordRequestService
			.post(passwordRequest)
			.subscribe(() => {
				this.toastr.info(
					'You have received an email to redefine your password'
				);
				this.router.navigate(['login']);
			});
	}
}

class PasswordFormGroup extends FormGroup {
	public email = new FormControl();

	constructor() {
		super({});

		this.email = <FormControl>(
			this.registerControl(
				'email',
				new FormControl('', [Validators.required, Validators.email])
			)
		);
	}
}
