export abstract class Cookie {
	public static get(key: string): string {
		const name = key + '=';
		const decodedCookie = decodeURIComponent(document.cookie);
		const ca = decodedCookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return '';
	}

	public static remove(key: string): void {
		document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
	}
}
