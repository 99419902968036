import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SystemModality} from '@app/models/system-modality.model';
import {Observable} from 'rxjs/Observable';
import {map} from 'rxjs/operators';

@Injectable()
export class SystemModalityService {
	constructor(private client: HttpClient) {}

	public get(): Observable<SystemModality[]> {
		return this.client
			.get<SystemModality[]>('/system-modalities')
			.pipe(
				map((response) =>
					response.map((systemModality) =>
						Object.assign(new SystemModality(), systemModality)
					)
				)
			);
	}
}
