import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

import {Measurement} from '@app/models/measurement.model';
import {Modality} from '@app/models/modality.model';
import {StudyPatient} from '@app/models/study-patient.model';
import {Visit} from '@app/models/visit.model';
import {StorageService} from '@app/services/storage.service';

import {environment} from '@env/environment';

import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Study} from '@app/models/study.model';
import {Bitstream} from '@app/models/bitstream.model';
import {View} from '@app/models/view.model';
import {FileUtils} from '@app/utils/file.utils';
import {BitstreamService} from '@app/services/api/bitstream.service';
import {ConfirmationComponent} from '@app/components/shared/modal/confirmation/index.component';
import {MeasurementBitstreamService} from '@app/services/api/measurement-bitstream.service';
import {ToastrService} from 'ngx-toastr';

@Component({
	templateUrl: 'viewer-modal.component.html',
	styleUrls: ['viewer-modal.component.scss'],
})
export class ViewerModalComponent implements OnInit {
	@ViewChild('image', {static: true}) image: ElementRef;
	@ViewChild('viewport', {static: true}) viewport;

	public bitstream: Bitstream;
	public FileUtils = FileUtils;
	public form: FormGroup;
	public loaded = false;
	public measurement: Measurement;
	public modalities: Modality[] = [];
	public study: Study;
	public studyPatient: StudyPatient[] = [];
	public views: View[] = [];
	public visits: Visit[] = [];

	public absoluteX = 0;
	public absoluteY = 0;
	private height;
	public posX = 0;
	public posY = 0;
	public realX = 0;
	public realY = 0;
	public relativeX = 0;
	public relativeY = 0;
	public scale: number;
	private width;

	constructor(
		private bitstreamService: BitstreamService,
		private measurementBitstreamService: MeasurementBitstreamService,
		public modal: BsModalRef,
		private modalService: BsModalService,
		public storageService: StorageService,
		private toastr: ToastrService,
		fb: FormBuilder
	) {
		this.form = fb.group({
			bitstream: null,
			modality: null,
			studyPatient: null,
			view: null,
			visit: null,
		});

		this.form.valueChanges.subscribe((values) => {
			this.form.disable({emitEvent: false, onlySelf: true});
			this.loaded = false;

			const measurement = this.study.measurements.find((measurement) => measurement.studyPatient.id === values.studyPatient && measurement.visit.id === values.visit && measurement.view.id === values.view && measurement.modality.id === values.modality);

			if (measurement !== this.measurement) {
				this.measurement = measurement;
				this.form.get('bitstream').patchValue(this.measurement.lastBitstream?.id, {emitEvent: false, onlySelf: true});
				values.bitstream = this.measurement.lastBitstream?.id;
			}

			const bitstream = this.measurement.studyPatient.study.bitstreams.find(bitstream => bitstream.id === values.bitstream);
			this.setImage(bitstream || new Bitstream());
		});
	}

	private home(): void {
		const rect = this.viewport.nativeElement.getBoundingClientRect();

		this.scale = Math.min(rect.height / this.image.nativeElement.naturalHeight, rect.width / this.image.nativeElement.naturalWidth);
		this.posX = (rect.width - this.image.nativeElement.naturalWidth * this.scale) / 2;
		this.posY = (rect.height - this.image.nativeElement.naturalHeight * this.scale) / 2;
	}

	public ngOnInit(): void {
		this.viewport.nativeElement.addEventListener(
			'mousedown',
			(e) => this.mouseDown(e),
			false
		);
		this.viewport.nativeElement.addEventListener(
			'mousemove',
			(e) => this.move(e),
			false
		);
		this.viewport.nativeElement.addEventListener(
			'mousewheel',
			(e) => this.zoom(e),
			false
		);
		this.viewport.nativeElement.addEventListener(
			'DOMMouseScroll',
			(e) => this.zoom(e),
			false
		);

		this.image.nativeElement.onload = () => {
			this.loaded = true;
			this.form.enable({emitEvent: false, onlySelf: true});

			if (
				this.height !== this.image.nativeElement.naturalHeight ||
				this.width !== this.image.nativeElement.naturalWidth
			) {
				this.home();
			}

			this.height = this.image.nativeElement.naturalHeight;
			this.width = this.image.nativeElement.naturalWidth;
		};

		this.image.nativeElement.onerror = () => {
			this.loaded = true;
			this.form.enable({emitEvent: false, onlySelf: true});
		};

		if (
			this.study &&
			this.study.measurements.length &&
			this.measurement.bitstreams
		) {
			this.form.patchValue({
				bitstream: this.measurement.lastBitstream.id,
				modality: this.measurement.modality.id,
				studyPatient: this.measurement.studyPatient.id,
				view: this.measurement.view.id,
				visit: this.measurement.visit.id,
			});
		} else this.setImage(this.measurement.bitstreams[0]);
	}

	private mouseDown(event): void {
		event.preventDefault();

		try {
			switch (event.which) {
				case 2:
					return this.pan(event);
				case 3:
					return this.home();
			}
		} catch (e) {}
	}

	private move(event): void {
		this.relativeX = event.offsetX - this.posX;
		this.relativeY = event.offsetY - this.posY;

		this.absoluteX = event.offsetX;
		this.absoluteY = event.offsetY;

		this.realX = Math.floor(this.relativeX / this.scale);
		this.realY = Math.floor(this.relativeY / this.scale);
	}

	private pan(event): void {
		const move = (moveEvent) => {
			moveEvent = window.event || moveEvent; // IE

			const deltaX = moveEvent.offsetX;
			const deltaY = moveEvent.offsetY;

			this.posX -= lastX - deltaX;
			this.posY -= lastY - deltaY;

			lastX = deltaX;
			lastY = deltaY;
		};

		const stop = (stopEvent) => {
			if (stopEvent.which !== which) {
				return;
			}

			this.viewport.nativeElement.removeEventListener('mousemove', move, false);
			this.viewport.nativeElement.removeEventListener('mouseup', stop, false);
		};

		event = window.event || event; // IE

		const which = event.which;
		let lastX = event.offsetX;
		let lastY = event.offsetY;

		this.viewport.nativeElement.addEventListener('mousemove', move, false);
		this.viewport.nativeElement.addEventListener('mouseout', stop, false);
		this.viewport.nativeElement.addEventListener('mouseup', stop, false);
	}

	public remove(): void {
		this.modalService
			.show(ConfirmationComponent, {
				initialState: {
					message:
						'This action will <strong>remove the bitstream</strong>: <em>#' +
						this.bitstream.id +
						'</em>',
				},
			})
			.content.onConfirm.subscribe(() => {
				this.measurementBitstreamService
					.delete(this.bitstream.measurementBitstream)
					.subscribe(() => {
						if (1 === this.measurement.bitstreams.length) {
							this.measurement.status = 1;
						}

						const index = this.measurement.bitstreams.indexOf(this.bitstream);
						this.measurement.bitstreams.splice(index, 1);
						this.form.updateValueAndValidity();

						this.toastr.success('Measurement bitstream removed');
					});
			});
	}

	private clear(): void {
		this.image.nativeElement.removeAttribute('src');
	}

	public setImage(bitstream: Bitstream): void {
		if (this.bitstream === bitstream) {
			return;
		}

		this.bitstream = bitstream;
		this.clear();

		if (!this.measurement.bitstreams) {
			this.form.enable({emitEvent: false, onlySelf: true});
			this.loaded = true;
			return;
		}

		this.image.nativeElement.src = environment.url.apiv2 + '/bitstreams/' + bitstream.id + '/blob?token=' + this.storageService.getEncodedToken();

		if (this.storageService.isTechnician()) {
			this.bitstreamService.getPatchesById(bitstream.id).subscribe((patches) => {bitstream.patches = patches});
		}
	}

	private zoom(event) {
		event.preventDefault();

		event = window.event || event; // IE
		const delta = Math.max(-1, Math.min(1, event.wheelDelta || -event.detail));

		const imageX = event.offsetX - this.posX;
		const imageY = event.offsetY - this.posY;

		const x1 = imageX / this.scale;
		const y1 = imageY / this.scale;

		if (delta < 0) this.scale /= 1.1;
		else this.scale *= 1.1;

		this.posX -= Math.floor((x1 - imageX / this.scale) * this.scale);
		this.posY -= Math.floor((y1 - imageY / this.scale) * this.scale);
	}
}
