import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Bitstream} from '@app/models/bitstream.model';

@Component({
	selector: 'app-bitstream-tree',
	templateUrl: 'index.component.html',
})
export class BitstreamTreeComponent {
	@Input() bitstream: Bitstream;
	@Input() depth = 0;

	@Output() onSelect = new EventEmitter<Bitstream>();
}
