export const environment = {
	cookie: {
		authToken: '_authToken',
	},
	document_tag_id: 7,
	production: false,
	url: {
		apiv2: 'https://beta.api.newtone.fr/v2',
		photoScale: 'https://beta.photoscale.newtone.fr',
		quoteMaker: 'https://beta.quotemaker.newtone.fr',
		visualViewer: 'https://beta.visualviewer.newtone.fr',
		ws: '',
	},
};

// export const environment = {
// 	cookie: {
// 		authToken: '_authToken'
// 	},
// 	document_tag_id: 7,
// 	production: false,
// 	url: {
// 		apiv2: 'https://beta.api.newtoneimaging.com/v2',
// 		photoScale: 'https://beta.photoscale.newtone.fr',
// 		quoteMaker: 'https://beta.quotemaker.newtone.fr',
// 		visualViewer: 'https://beta.visualviewer.newtone.fr',
// 		ws: ''
// 	}
// };

// export const environment = {
// 	cookie: {
// 		authToken: 'authToken'
// 	},
// 	document_tag_id: 7,
// 	production: true,
// 	url: {
// 		apiv2: 'https://api.newtoneimaging.com/v2',
// 		photoScale: 'https://photoscale.newtone.fr',
// 		quoteMaker: 'https://quotemaker.newtone.fr',
// 		visualViewer: 'https://visualviewer.newtone.fr',
// 		ws: 'wss://ws.newtone.fr/'
// 	}
// };
