import {Component, Input, OnInit} from '@angular/core';

import {ModalService} from '@app/services/ngx-bootstrap/modal-service.service';

import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-maintenance',
	templateUrl: './maintenance.component.html'
})
export class MaintenanceComponent {
	constructor(
		public modal: BsModalRef,
		public modalService: ModalService
	) {}
}
