<div class="site-wrapper">
	<div class="site-wrapper-inner">
		<div style="margin : 0 auto ; max-width : 330px ; text-align : center">
			<img class="img-responsive" src="./assets/img/logo.png" alt="Newtone">

			<div class="panel panel-default" style="margin: 20px; margin-top: 50px; position: relative">
				<div *ngIf="!environnement.production" class="ribbon ribbon-top-right"><span>βeta</span></div>

				<form [formGroup]="form" (submit)="submit()" class="form-signin">
					<div class="form-group input-group">
						<span class="input-group-addon">
							<span class="glyphicon glyphicon-user" aria-hidden="true"></span>
						</span>

						<input class="form-control" type="text" placeholder="Username" autofocus formControlName="username">
					</div>

					<div class="form-group input-group">
						<span class="input-group-addon">
							<span class="glyphicon glyphicon-lock" aria-hidden="true"></span>
						</span>

						<input #password class="form-control" type="password" placeholder="Password" formControlName="password">

						<span class="input-group-addon" style="cursor: pointer" (mousedown)="password.type = 'text'" (mouseup)="password.type = 'password'" (mouseout)="password.type = 'password'">
							<span class="glyphicon glyphicon-eye-open" aria-hidden="true"></span>
						</span>
					</div>

					<button class="btn btn-lg btn-newtone btn-block" type="submit" [disabled]="!form.valid">Sign in</button>

					<div class="text-center" style="padding-top: 10px">
						<a href="#" routerLink="/password-requests">Forgot my password</a>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
