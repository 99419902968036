import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Study} from '@app/models/study.model';
import {of} from 'rxjs';

@Component({
	selector: 'app-study-picker',
	templateUrl: 'index.component.html',
})
export class StudyPickerComponent {
	@Input() studies: Study[] = [];
	@Output() onApply = new EventEmitter<Study>();

	public form: FormGroup;

	constructor(formBuilder: FormBuilder) {
		this.form = formBuilder.group({
			needle: ['', [Validators.required, (e) => this.validateUser(e)]],
		});
	}

	private getStudy(needle: string): Study {
		return this.studies.find(
			(study) => study.name.toLowerCase() === needle.toLowerCase()
		);
	}

	public getStudySource() {
		return of(
			this.studies.filter((study) => {
				const needle = this.form.getRawValue().needle.toLowerCase();
				return study.name.toLowerCase().includes(needle);
			})
		);
	}

	public submit(): void {
		this.onApply.emit(this.getStudy(this.form.getRawValue().needle));
		this.form.patchValue({needle: ''});
	}

	private validateUser(control: FormControl) {
		return !control.value || this.getStudy(control.value)
			? null
			: {
					name: {
						valid: false,
					},
			  };
	}
}
