export class DeviceStatistic {
	public activeStudies: number;
	public ambientAlerts: number;
	public createdAt: Date;
	public errors: number;
	public imagesReceived: number;
	public imagesSaved: number;
	public id: number;
	public offlineStudies: number;
	public onlineStudies: number;
	public shutterCount: number;
	public totalAmbientAlerts: number;
	public totalErrors: number;
	public totalImages: number;
	public totalStudies: number;
	public uploadedAt: Date;
}
