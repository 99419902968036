export abstract class FileUtils {
	public static getHumanReadable(size: number): string {
		const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		let i = 0;

		while (size >= 1024) {
			size /= 1024;
			++i;
		}

		return size.toFixed(1) + ' ' + units[i];
	}

	public static prefix(filename: string, prefix: string): string {
		const parts = filename.split('.');
		const extension = parts.pop();
		return [...parts, prefix, '.', extension].join('');
	}
}
