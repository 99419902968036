import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Tag} from '@app/models/tag.model';
import {Observable} from 'rxjs';

@Injectable()
export class TagService {
	constructor(private client: HttpClient) {}

	public get(): Observable<Tag[]> {
		return this.client
			.get<Tag[]>('/tags')
			.pipe(
				map((response) => response.map((tag) => Object.assign(new Tag(), tag)))
			);
	}

	public post(tag: Tag): Observable<Tag> {
		return this.client
			.post<Tag>('/tags', {
				name: tag.name,
				type: {
					id: tag.type.id,
				},
			})
			.pipe(map((response) => Object.assign(new Tag(), response)));
	}
}
