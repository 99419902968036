import {MeasurementBitstream} from '@app/models/measurement-bitstream.model';

export class Patch {
	public id: number;
	public name: string;
	public position: number;
	public measurementBitstream: MeasurementBitstream;

	public ctm: number;

	public l: number;
	public a: number;
	public b: number;

	public stdDevL: number;
	public stdDevA: number;
	public stdDevB: number;

	public x1: number;
	public y1: number;
	public x2: number;
	public y2: number;
}
