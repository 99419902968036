import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BitstreamMask} from '@app/models/bitstream-mask.model';
import {Observable} from 'rxjs';

@Injectable()
export class BitstreamMaskService {
	constructor(private client: HttpClient) {}

	public delete(bitstreamMask: BitstreamMask): Observable<Response> {
		return this.client.delete<Response>('/bitstream-masks/' + bitstreamMask.id);
	}

	public post(bitstreamMask: BitstreamMask): Observable<BitstreamMask> {
		return this.client
			.post<BitstreamMask>('/bitstream-masks', {
				bitstream: {
					id: bitstreamMask.bitstream.id,
				},
				comment: bitstreamMask.comment,
				name: bitstreamMask.name,
			})
			.pipe(map((response) => Object.assign(new BitstreamMask(), response)));
	}

	public put(bitstreamMask: BitstreamMask): Observable<BitstreamMask> {
		return this.client
			.put<BitstreamMask>('/bitstream-masks/' + bitstreamMask.id, {
				bitstream: {
					id: bitstreamMask.bitstream.id,
				},
				comment: bitstreamMask.comment,
				id: bitstreamMask.id,
				mask: {
					id: bitstreamMask.mask ? bitstreamMask.mask.id : null,
				},
				name: bitstreamMask.name,
			})
			.pipe(map((response) => Object.assign(new BitstreamMask(), response)));
	}

	public upload(
		bitstreamMask: BitstreamMask,
		file,
		name: string
	): Observable<BitstreamMask> {
		const formData = new FormData();
		formData.append('file', file, name);

		return this.client
			.post<BitstreamMask>(
				'/bitstream-masks/' + bitstreamMask.id + '/blob',
				formData
			)
			.pipe(map((response) => Object.assign(new BitstreamMask(), response)));
	}
}
