<div class="modal-header">
	<button type="button" class="close" (click)="modal.hide()">&times;</button>
	<h4 class="modal-title">Add modality</h4>
</div>

<div class="modal-body">
	<div *ngFor="let modality of modalities">
		{{ modality.deviceName }}
	</div>
</div>
