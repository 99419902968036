<div class="modal-header">
	<h4 class="modal-title">Maintenance</h4>
</div>

<div class="modal-body text-center">
	<div class="row">
		<div class="col-sm-5" style="display: flex;align-items: center;height: 150px">
			<img src="./assets/img/logo.png" alt="Newtone" style="max-height: 100%">
		</div>

		<div class="col-sm-7" style="display: flex;align-items: center;height: 150px">
			<div>
				<div class="form-group">
					We are sorry, but the Newtone CiPasS is currently under maintenance.<br />
					Please wait and retry after.
				</div>
				<button type="button" class="btn btn-primary" (click)="modal.hide()">OK</button>
			</div>
		</div>
	</div>
</div>
