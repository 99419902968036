export abstract class Account {
	abstract get username(): string;

	public id: number;
	public numberOfStudies: number;
	public status: number;
	public type: number;
	public visit: string;

	get isDevice(): boolean {
		return this.type === 0;
	}

	get isGuest(): boolean {
		return this.type === 2;
	}

	get isUser(): boolean {
		return this.type === 2;
	}
}
