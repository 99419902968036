import {Document} from '@app/models/document.model';

export class Software {
	public extra: Object;
	public id: number;
	public name: string;
	public url: string;
	public version: string;

	public documents: Document[];
}
