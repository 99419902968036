import {Account} from '@app/models/account.model';
import {System} from '@app/models/system.model';

export class Device extends Account {
	public ambientAlerts: number;
	public customer: string;
	public description: string;
	public errors: number;
	public illustration: boolean;
	public imagesReceived: number;
	public imagesSaved: number;
	public offlineStudies: number;
	public onlineStudies: number;
	public pic: string;
	public serial: string;
	public shutterCount: number;
	public system: System;
	public version: string;

	public get longDescription(): string {
		const properties = [this.serial];

		if (this.customer) {
			properties.push(this.customer);
		}

		if (this.description) {
			properties.push(this.description);
		}

		return properties.join(' - ');
	}

	public get username(): string {
		return this.serial;
	}

	public online = false;
}
