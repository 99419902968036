import {ModuleWithProviders} from '@angular/core';
import {RouterModule} from '@angular/router';

import {LoginComponent} from '@app/components/login/login.component';
import {PasswordRecoveryComponent} from '@app/components/password-recovery/index.component';
import {PasswordRequestsComponent} from '@app/components/password-request/index.component';

export const appRoutingProviders: any[] = [];

export const appRouting: ModuleWithProviders<any> = RouterModule.forRoot([
	{
		path: 'password-requests', children: [
			{path: '', component: PasswordRequestsComponent},
			{path: ':token', component: PasswordRecoveryComponent}
		]
	},
	{path: 'login', component: LoginComponent},
	{path: '**', redirectTo: '/'}
]);
