import {Component, OnDestroy} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {PasswordRequestService} from '@app/services/api/password-request.service';
import {PasswordRequest} from '@app/models/password-request.model';

import {Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';

@Component({
	templateUrl: 'index.component.html',
})
export class PasswordRecoveryComponent implements OnDestroy {
	public form = new PasswordFormGroup();
	public loaded = false;
	private subscription: Subscription;

	constructor(
		private activatedRoute: ActivatedRoute,
		private passwordRequestService: PasswordRequestService,
		private router: Router,
		private toastr: ToastrService
	) {}

	public ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	public submit(): void {
		const passwordRequest = new PasswordRequest();
		passwordRequest.token = this.activatedRoute.snapshot.params['token'];
		passwordRequest.password = this.form.password.value;

		this.subscription = this.passwordRequestService
			.put(passwordRequest)
			.subscribe(() => {
				this.toastr.success('Password updated');
				this.router.navigate(['login']);
			});
	}
}

class PasswordFormGroup extends FormGroup {
	public confirmation = new FormControl();
	public password = new FormControl();

	private static passwordValidator(control: FormControl) {
		const form = <PasswordFormGroup>control.parent;
		return control.value && form.confirmation.value === form.password.value
			? null
			: {
					code: {
						valid: false,
					},
			  };
	}

	constructor() {
		super({});

		this.confirmation = <FormControl>(
			this.registerControl(
				'confirmation',
				new FormControl('', PasswordFormGroup.passwordValidator)
			)
		);
		this.password = <FormControl>(
			this.registerControl(
				'password',
				new FormControl(
					'',
					Validators.pattern('^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}$')
				)
			)
		);
	}
}
