import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core';

import {Account} from '@app/models/account.model';
import {AccountSession} from '@app/models/account-session.model';

import {LogGroup} from '@app/forms/log.group';

import * as XLSX from 'xlsx';

type AOA = any[][];

@Component({
	selector: 'app-account-session',
	templateUrl: './account-session.component.html',
	styleUrls: ['./account-session.component.scss'],
})
export class AccountSessionComponent implements OnInit, OnChanges {
	@Input() private account: Account;
	@Input() public accountSessions: AccountSession[] = [];
	@Input() public accountType: string;
	public actualAccountSessions: AccountSession[] = [];
	public chronologicSort = false;
	public form: LogGroup;
	public itemsPerPage = 15;
	public page = 1;
	public sessionLengthSort = 'asc';

	public data: AOA = [
		[1, 2],
		[3, 4],
	];

	constructor() {}

	ngOnInit() {
		this.form = new LogGroup();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.accountSessions.currentValue.length > 0) {
			this.accountSessions.forEach((accountSession) => {
				if (accountSession.disconnectionDate !== null) {
					const connectionDate = new Date(accountSession.connectionDate);
					const disconnectionDate = new Date(accountSession.disconnectionDate);
					accountSession.sessionLength =
						disconnectionDate.getTime() - connectionDate.getTime();
				} else {
					accountSession.sessionLength = 0;
				}
			});
			this.toggleSortLogsByDate(true);
		}
	}

	public export(): void {
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		const lines: any[] = [];

		lines.push([
			'Connection date',
			'Disconnection date',
			'Session length',
			'User agent',
		]);

		this.accountSessions.forEach((accountSession) => {
			lines.push([
				accountSession.connectionDate,
				accountSession.disconnectionDate,
				accountSession.sessionLength / 1000,
				accountSession.userAgent,
			]);
		});

		const name = (this.accountType = 'user'
			? this.account.username
			: 'Device' + this.account.id);
		const title = name + ' logs';

		XLSX.utils.book_append_sheet(
			wb,
			XLSX.utils.aoa_to_sheet(lines),
			title.substr(0, 31)
		);
		XLSX.writeFile(wb, 'account_sessions_' + name + '.xlsx');
	}

	public filter(): void {
		if (this.form.from.value !== '' && this.form.to.value !== '') {
			this.actualAccountSessions = this.accountSessions.filter(
				(accountSession) =>
					new Date(accountSession.connectionDate).getTime() >=
						new Date(this.form.from.value).getTime() &&
					new Date(accountSession.connectionDate).getTime() <=
						new Date(this.form.to.value).getTime()
			);
		}
	}

	public toggleSortLogsByDate(init = false): void {
		if (!init) {
			this.chronologicSort = !this.chronologicSort;
		}

		if (this.chronologicSort) {
			this.actualAccountSessions = this.accountSessions.sort(
				(a, b) =>
					new Date(a.connectionDate).getTime() -
					new Date(b.connectionDate).getTime()
			);
		} else {
			this.actualAccountSessions = this.accountSessions.sort(
				(a, b) =>
					new Date(b.connectionDate).getTime() -
					new Date(a.connectionDate).getTime()
			);
		}
	}

	public toggleSortLogsBySessionLength(type: string): void {
		if (type === 'asc') {
			this.actualAccountSessions = this.accountSessions.sort(
				(a, b) => a.sessionLength - b.sessionLength
			);
		} else if (type === 'desc') {
			this.actualAccountSessions = this.accountSessions.sort(
				(a, b) => b.sessionLength - a.sessionLength
			);
		}
		this.sessionLengthSort = type;
	}

	public reset(): void {
		this.chronologicSort = true;
		this.toggleSortLogsByDate();
	}
}
