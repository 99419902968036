<div>
  <nav class="navbar navbar-default navbar-fixed-top">
    <div class="container">
      <div class="navbar-header">
        <a class="navbar-brand" routerLink="/">Newtone</a>
      </div>
    </div>
  </nav>

  <div class="container" style="padding-top : 80px ;">
    <div class="alert alert-info row" role="alert">
      If you forgot your password and would like to reset it, please follow the steps below:
      <ul style="padding-bottom: 10px">
        <li>Enter an accurate and up-to-date email address where you want to receive the password reset email.</li>
        <li>Click on "Submit"</li>
        <li>Check your email inbox. A message is sent to the email address provided in step 1.</li>
        <li>Click the reset link in that email.</li>
        <li>Follow the instructions and choose a new password.</li>
      </ul>

      <b>Note:</b> An accurate and up-to-date email address is the best step to ensure you never lose access to your account
    </div>

    <form class="form-horizontal" [formGroup]="form" (submit)="submit()">
      <div class="form-group">
        <label class="font-weight-bold" for="email">Email*</label>
        <input type="email" class="form-control" id="email" autocomplete="off" formControlName="email" [class.is-invalid]="form.email.value && !form.email.valid">
        <small *ngIf="form.email.value && !form.email.valid" class="form-text text-danger">Invalid email</small>
      </div>

      <p class="text-center">
        <button class="btn btn-dark" type="submit" [disabled]="!form.valid">Submit</button>
      </p>
    </form>
  </div>
</div>
