import {Account} from '@app/models/account.model';
import {Device} from '@app/models/device.model';
import {User} from '@app/models/user.model';

export abstract class Log {
	public id: number;
	public account: Account | Device | User;
	public createdAt: Date;
	public type: number;
	public ip: string;
	public userAgent: string;
}
