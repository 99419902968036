import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Ethnicity} from '@app/models/ethnicity.model';
import {Observable} from 'rxjs';

@Injectable()
export class EthnicityService {
	constructor(private client: HttpClient) {}

	public get(): Observable<Ethnicity[]> {
		return this.client
			.get<Ethnicity[]>('/ethnicities')
			.pipe(
				map((response) =>
					response.map((ethnicity) => Object.assign(new Ethnicity(), ethnicity))
				)
			);
	}
}
