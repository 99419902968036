import {TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';

export class ModalService extends BsModalService {
	public show(
		content: string | TemplateRef<any> | any,
		config?: ModalOptions
	): BsModalRef {
		if (!config || !config.hasOwnProperty('ignoreBackdropClick')) {
			this.config.ignoreBackdropClick = true;
		}

		return super.show(content, config);
	}
}
