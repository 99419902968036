import {Bitstream} from '@app/models/bitstream.model';
import {Device} from '@app/models/device.model';
import {Modality} from '@app/models/modality.model';
import {Measurement} from '@app/models/measurement.model';
import {MeasurementBitstream} from '@app/models/measurement-bitstream.model';
import {Pretreatment} from '@app/models/pretreatment.model';
import {Product} from '@app/models/product.model';
import {StudyPatient} from '@app/models/study-patient.model';
import {StudyAttachment} from '@app/models/study-attachment.model';
import {StudyUser} from '@app/models/study-user.model';
import {System} from '@app/models/system.model';
import {View} from '@app/models/view.model';
import {Visit} from '@app/models/visit.model';

export class Study {
	public archived: boolean;
	public connected: boolean;
	public cro: string;
	public date: string;
	public id: number;
	public imported: boolean;
	public lastUpdate: string;
	public name: string;
	public numberOfMeasurementsExpected: number;
	public numberOfMeasurementsReceived: number;
	public numberOfModalities: number;
	public numberOfProducts: number;
	public numberOfStudyPatients: number;
	public numberOfViews: number;
	public numberOfVisits: number;
	public reference: string;
	public sponsor: string;
	public status: number;
	public system: System;

	public bitstreams: Bitstream[] = [];
	public devices: Device[] = [];
	public modalities: Modality[] = [];
	public measurements: Measurement[] = [];
	public measurementBitstreams: MeasurementBitstream[] = [];
	public pretreatments: Pretreatment[] = [];
	public products: Product[] = [];
	public studyPatients: StudyPatient[] = [];
	public studyAttachments: StudyAttachment[] = [];
	public studyUsers: StudyUser[] = [];
	public views: View[] = [];
	public visits: Visit[] = [];

	get progress(): number {
		return (
			this.numberOfMeasurementsReceived / this.numberOfMeasurementsExpected
		);
	}
}
