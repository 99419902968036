import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BitstreamProduct} from '@app/models/bitstream-product.model';
import {Observable} from 'rxjs';

@Injectable()
export class BitstreamProductService {
	constructor(private client: HttpClient) {}

	public post(
		bitstreamProduct: BitstreamProduct
	): Observable<BitstreamProduct> {
		return this.client
			.post<BitstreamProduct>('/bitstream-products', {
				bitstream: {
					id: bitstreamProduct.bitstream.id,
				},
				product: {
					id: bitstreamProduct.product.id,
				},
			})
			.pipe(map((response) => Object.assign(new BitstreamProduct(), response)));
	}
}
