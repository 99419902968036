<!--<ng-container *ngFor="let pretreatment of bitstream.pretreatments">-->
<!--	<ul>-->
<!--		<li>-->
<!--			<a href="#" (click)="onSelect.emit(pretreatment.bitstream); false">{{ pretreatment.bitstream.filename }}</a>-->
<!--			<bitstream-tree [bitstream]="pretreatment.bitstream" (onSelect)="onSelect.emit($event)"></bitstream-tree>-->
<!--		</li>-->
<!--	</ul>-->
<!--</ng-container>-->

<option>------</option>
<!--<option>{{ '-'.repeat(depth) }} {{ bitstream.filename }}</option>-->
<!--<ng-container *ngFor="let pretreatment of bitstream.pretreatments">-->
<!--	<bitstream-tree [bitstream]="pretreatment.bitstream" [depth]="depth + 1" (onSelect)="onSelect.emit($event)"></bitstream-tree>-->
<!--</ng-container>-->
