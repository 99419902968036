<div class="modal-header">
	<h4 class="modal-title pull-left">{{ title }}</h4>
	<button type="button" class="close pull-right" aria-label="Close" (click)="modal.hide()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="clearfix modal-body">
	<form [formGroup]="form" (submit)="submit()">
		<div class="form-group">
			<label class="font-weight-bold" for="value">{{ label }}</label>
			<input type="text" class="form-control" id="value" formControlName="value" autocomplete="off" maxlength="191" [class.is-invalid]="form.get('value').dirty && !form.get('value').valid">
		</div>

		<button type="submit" class="btn btn-dark float-right" [disabled]="!form.valid">{{ button }}</button>
	</form>
</div>
