import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserDevice} from '@app/models/user-device.model';
import {Observable} from 'rxjs';

@Injectable()
export class UserDeviceService {
	constructor(private client: HttpClient) {}

	public delete(userDevice: UserDevice): Observable<Response> {
		return this.client.delete<Response>('/user-devices/' + userDevice.id);
	}

	public post(userDevice: UserDevice): Observable<UserDevice> {
		return this.client
			.post<UserDevice>('/user-devices', {
				device: {
					id: userDevice.device.id,
				},
				user: {
					id: userDevice.user.id,
				},
			})
			.pipe(map((response) => Object.assign(new UserDevice(), response)));
	}
}
