import {Component} from '@angular/core';

import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-browser-detection',
	templateUrl: './browser-detection.component.html',
	styleUrls: ['./browser-detection.component.scss'],
})
export class BrowserDetectionComponent {
	constructor(public modal: BsModalRef) {}
}
