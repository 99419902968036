<div class="modal-header">
	<button type="button" class="close pull-right" aria-label="Close" (click)="modal.hide()">
		<span aria-hidden="true">&times;</span>
	</button>

	<div *ngIf="storageService.isAdmin() && measurement.status === 2 && bitstream?.measurementBitstream" class="dropdown pull-right">
		<button class="btn btn-link btn-xs dropdown-toggle" type="button" data-toggle="dropdown">
			<span class="glyphicon glyphicon-option-vertical" aria-hidden="true"></span>
		</button>

		<ul class="dropdown-menu dropdown-menu-right">
			<li><a href="#" (click)="remove(); false">Remove bitstream</a></li>
		</ul>
	</div>

	<h4 *ngIf="measurement" class="modal-title">Measurement #{{ measurement.id }}</h4>
</div>

<div class="modal-dashboard">
	<div class="modal-sidebar" *ngIf="study && study.modalities && study.views && study.visits">
		<div class="panel-default">
			<div class="panel-heading">
				Filters
			</div>

			<div class="panel-body">
				<form [formGroup]="form">
					<div class="form-group">
						<label for="modality">Study patient</label>
						<select class="form-control" id="patient" formControlName="studyPatient">
							<option *ngFor="let studyPatient of study.studyPatients" [ngValue]="studyPatient.id">{{ studyPatient.code }}</option>
						</select>
					</div>

					<div class="form-group">
						<label for="modality">Modality</label>
						<select class="form-control" id="modality" formControlName="modality">
							<option *ngFor="let modality of study.modalities" [ngValue]="modality.id">{{ modality.name }}</option>
						</select>
					</div>

					<div class="form-group">
						<label for="view">View</label>
						<select class="form-control" id="view" formControlName="view">
							<option *ngFor="let view of study.views" [ngValue]="view.id">{{ view.deviceName }}</option>
						</select>
					</div>

					<div class="form-group">
						<label for="visit">Visit</label>
						<select class="form-control" id="visit" formControlName="visit">
							<option *ngFor="let visit of study.visits" [ngValue]="visit.id">{{ visit.name }}</option>
						</select>
					</div>

					<div class="form-group">
						<label for="pretreatment">File</label>
						<select class="form-control" id="pretreatment" formControlName="bitstream">
							<ng-template #recursiveList let-b="bitstream" let-depth="depth">
								<option [ngValue]="b.id">
									<ng-container [ngSwitch]="0 === depth">
										<ng-container *ngSwitchCase="true">Repetition {{ b.measurementBitstream.rank + 1 }}</ng-container>
										<ng-container *ngSwitchCase="false">{{ '&nbsp;&nbsp;&nbsp;&nbsp;'.repeat(depth) }} {{ b.concatenedTags || b.filename }}</ng-container>
									</ng-container>
								</option>

								<ng-container *ngFor="let pretreatment of b.pretreatments">
									<ng-container *ngTemplateOutlet="recursiveList; context:{ bitstream: pretreatment.bitstream, depth: depth + 1 }"></ng-container>
								</ng-container>
							</ng-template>

							<ng-container *ngFor="let bs of measurement.bitstreams">
								<ng-container *ngTemplateOutlet="recursiveList; context:{ bitstream: bs, depth: 0 }"></ng-container>
							</ng-container>

						</select>
					</div>
				</form>
			</div>

			<div class="panel-heading">
				Bitstream
			</div>

			<div class="panel-body">
				<div class="form-group">
					<b>Filename:</b><br />
					{{ bitstream?.filename }}
				</div>

				<div class="form-group">
					<b>Size:</b><br />
					{{ FileUtils.getHumanReadable(bitstream?.sizeBytes) }}
				</div>

				<div class="form-group">
					<b>Upload date:</b><br />
					{{ bitstream?.date | date : 'yyyy/MM/dd HH:mm:ss' }}
				</div>

				<div class="form-group">
					<b>Checksum:</b><br />
					{{ bitstream?.checksum }}
				</div>
			</div>
		</div>
	</div>

	<div class="modal-main">
		<div class="modal-viewer" oncontextmenu="return false">
			<div style="position: absolute" [style.height.px]="image.naturalHeight * scale" [style.left.px]="posX" [style.top.px]="posY" [style.width.px]="image.naturalWidth * scale">
				<div style="position:relative;height: 100%;width: 100%">
					<img #image alt="..." crossorigin="" style="position:absolute;height: 100%;width: 100%" (load)="error.style.display = 'none'" (error)="error.style.display = 'block'">

					<svg *ngIf="bitstream?.patches?.length > 0" viewBox="0 0 4000 6000" style="position:absolute;height: 100%;width: 100%">
						<rect *ngFor="let patch of bitstream.patches" [attr.x]="patch.x1" [attr.y]="patch.y1" [attr.width]="patch.x2 - patch.x1" [attr.height]="patch.y2 - patch.y1" fill-opacity="0" style="stroke:pink;stroke-width:5"></rect>
					</svg>
				</div>
			</div>

			<div class="viewport" #viewport></div>

			<div style="position: absolute; left: 5px; top: 10px">
				<span class="label label-primary" style="margin: 0 5px" *ngFor="let tag of bitstream.tags">{{ tag.name }}</span>
			</div>

			<div *ngIf="!loaded" class="viewport" style="background-color: white;z-index:99">
				<div class="loader">
					<div class="dummy-child"></div>
					<div class="spinner"></div>
				</div>
			</div>

			<div #error class="error viewport" style="background-color: white;display:none;z-index:99">
				<div class="dummy-child"></div>
				<div class="spinner"><em>Unable to display image</em></div>
			</div>
		</div>
	</div>
</div>
