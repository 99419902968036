import {Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {StudyDownloaderDocumentationComponent} from '@app/components/study/downloader/documentation/index.component';
import {ModalService} from '@app/services/ngx-bootstrap/modal-service.service';

@Component({
	templateUrl: 'index.component.html',
})
export class NewsComponent {
	constructor(public modal: BsModalRef, public modalService: ModalService) {}

	public getSoftware(): void {
		this.modalService.show(StudyDownloaderDocumentationComponent, {
			class: 'modal-lg',
		});
	}
}
