<nav class="navbar navbar-default navbar-fixed-top">
	<div class="container">
		<div class="navbar-header">
			<button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
				<span class="sr-only">Toggle navigation</span>
				<span class="icon-bar"></span>
				<span class="icon-bar"></span>
				<span class="icon-bar"></span>
			</button>
		</div>

		<div class="navbar-header">
			<img class="img-responsive" src="./assets/img/logo.png" alt="Newtone" style="margin-top: 3px; padding-right: 15px;max-height: 35px">
		</div>

		<div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
			<ul class="nav navbar-nav">
				<li routerLinkActive="active" *ngIf="storageService.isQuoteMaker()"><a [href]="environnement.url.quoteMaker">Quotes</a></li>
				<li routerLinkActive="active"><a routerLink="/studies" >Studies</a></li>

				<li class="dropdown" routerLinkActive="active" *ngIf="storageService.isTechnician()">
					<a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Maintenance <span class="caret"></span></a>
					<ul class="dropdown-menu">
						<li routerLinkActive="active"><a routerLink="/measurements">Measurements</a></li>
						<li routerLinkActive="active"><a routerLink="/patches">Patches</a></li>
					</ul>
				</li>

				<li class="dropdown" routerLinkActive="active" *ngIf="storageService.isAdmin()">
					<a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Administration <span class="caret"></span></a>
					<ul class="dropdown-menu">
						<li routerLinkActive="active"><a routerLink="/devices">Devices</a></li>
						<li routerLinkActive="active"><a routerLink="/documents">Documents</a></li>
						<li routerLinkActive="active"><a routerLink="/logs">Logs</a></li>
						<li routerLinkActive="active"><a routerLink="/messages">Messages</a></li>
						<li routerLinkActive="active"><a routerLink="/users">Users</a></li>
						<li routerLinkActive="active"><a routerLink="/statistics">Statistics</a></li>
						<li routerLinkActive="active"><a routerLink="/systems">Systems</a></li>
						<li routerLinkActive="active"><a routerLink="/softwares">Softwares</a></li>
						<li routerLinkActive="active"><a routerLink="/tags">Tags</a></li>
						<li routerLinkActive="active"><a routerLink="/views">Views</a></li>
					</ul>
				</li>
			</ul>

			<ul class="nav navbar-nav navbar-right">
				<li class="dropdown" routerLinkActive="active">
					<a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">{{ storageService.getFullName() }} <span class="caret"></span></a>
					<ul class="dropdown-menu">
						<li class="dropdown-header">Account</li>
						<li><a routerLink="/documents">Documentation</a></li>
						<li><a href="#" (click)="setPassword(); false">Set password...</a></li>
						<li class="divider"></li>
						<li class="dropdown-header">Services</li>
						<li><a [href]="environnement.url.photoScale">PhotoScale</a></li>
						<li><a [href]="environnement.url.quoteMaker">QuoteMaker</a></li>
						<li><a [href]="environnement.url.visualViewer">VisualViewer</a></li>
						<li class="divider"></li>
						<li class="dropdown-header">Softwares</li>
						<li><a href="#" (click)="getSoftware(); false">StudiesDownloader...</a></li>
						<li class="divider"></li>
						<li><a href="#" (click)="logout(); false">Log out</a></li>
					</ul>
				</li>
			</ul>
		</div>
	</div>
</nav>

<div class="container" style="padding-top : 80px ;">
	<router-outlet></router-outlet>
</div>
