<div class="modal-header">
	<h4 class="modal-title pull-left">Content</h4>
	<button type="button" class="close pull-right" aria-label="Close" (click)="modal.hide()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="clearfix modal-body">
	<pre>{{ text }}</pre>
</div>
