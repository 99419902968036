import {Component, Input} from '@angular/core';

/**
 * @see https://jsfiddle.net/Guruprasad_Rao/bypbqboe/64/
 */
@Component({
	selector: 'app-indicator',
	template: `<div>
		<ng-content></ng-content>
		<span class="pull-right">
			<sub
				*ngIf="sort == value && direction == -1"
				class="glyphicon glyphicon-triangle-bottom"
				aria-hidden="true"
			></sub>
			<sup
				*ngIf="sort == value && direction == 1"
				class="glyphicon glyphicon-triangle-top"
				aria-hidden="true"
			></sup>
		</span>
	</div>`,
})
export class IndicatorComponent {
	@Input() direction: number;
	@Input() sort: number;
	@Input() value: number;
}
