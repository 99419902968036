import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {StudyLog} from '@app/models/study-log';
import {Observable} from 'rxjs';

@Injectable()
export class StudyLogService {
	constructor(private client: HttpClient) {}

	public get(): Observable<StudyLog[]> {
		return this.client
			.get<StudyLog[]>('/study-logs')
			.pipe(
				map((response) =>
					response.map((studyLog) => Object.assign(new StudyLog(), studyLog))
				)
			);
	}
}
