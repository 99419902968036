import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Study} from '@app/models/study.model';
import {StudyAttachment} from '@app/models/study-attachment.model';
import {Observable} from 'rxjs';

@Injectable()
export class StudyAttachmentService {
	constructor(private client: HttpClient) {}

	public upload(study: Study, file: File): Observable<StudyAttachment> {
		const formData = new FormData();
		formData.append('file', file, file.name);

		return this.client
			.post<StudyAttachment>(
				'/studies/' + study.id + '/study-attachments',
				formData
			)
			.pipe(map((response) => Object.assign(new StudyAttachment(), response)));
	}
}
