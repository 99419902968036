import {Component, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';

import {ConfirmationComponent} from '@app/components/shared/modal/confirmation/index.component';
import {PasswordRequest} from '@app/models/password-request.model';
import {AuthenticationService} from '@app/services/authentication.service';
import {AuthTokenService} from '@app/services/api/auth-token.service';
import {PasswordRequestService} from '@app/services/api/password-request.service';
import {ModalService} from '@app/services/ngx-bootstrap/modal-service.service';
import {StorageService} from '@app/services/storage.service';
import {Cookie} from '@app/utils/cookie.util';

import {environment} from '@env/environment';
import {Subscription} from 'rxjs';
import {StudyDownloaderDocumentationComponent} from '@app/components/study/downloader/documentation/index.component';
import {ToastrService} from 'ngx-toastr';

@Component({
	templateUrl: 'dashboard.component.html',
	styleUrls: ['dashboard.component.scss'],
})
export class DashboardComponent implements OnDestroy {
	public environnement = environment;
	private subscription: Subscription;

	constructor(
		private authTokenService: AuthTokenService,
		private modalService: ModalService,
		private passwordRequestService: PasswordRequestService,
		private router: Router,
		public storageService: StorageService,
		private toastr: ToastrService
	) {
		if (
			!this.storageService.getToken() ||
			AuthenticationService.isTokenExpired()
		) {
			this.getLogin();
		}
	}

	private getLogin(): void {
		this.router.navigateByUrl('login');
		localStorage.clear();
	}

	public getSoftware(): void {
		this.modalService.show(StudyDownloaderDocumentationComponent, {
			class: 'modal-lg',
		});
	}

	public logout(): void {
		Cookie.remove(environment.cookie.authToken);
		this.authTokenService
			.delete(this.storageService.getAuthToken())
			.subscribe(() => this.getLogin());
	}

	public ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	public open(url: string): void {
		const popup = window.open(url);
		popup.onload = () => {
			popup.postMessage(this.storageService.getAuthToken(), url);
		};
	}

	public setPassword(): void {
		this.modalService
			.show(ConfirmationComponent, {
				initialState: {
					message: 'An email will be sent you to redefine your password',
				},
			})
			.content.onConfirm.subscribe(() => {
				const passwordRequest = new PasswordRequest();
				passwordRequest.email = this.storageService.getUser().email;

				this.subscription = this.passwordRequestService
					.post(passwordRequest)
					.subscribe(() => {
						this.toastr.info(
							'You have received an email to redefine your password'
						);
					});
			});
	}
}
