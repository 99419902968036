export class View {
	public alias: string;
	public area: DeprecatedArea;
	public id: number;
	public view: DeprecatedView;
	public deviceName: string;
	public name: string;
}

class DeprecatedArea {
	public id: number;
	public name: string;
	public views: any[] = [];
}

class DeprecatedView {
	public deviceName: string;
	public id: number;
	public name: string;
}
