import {User} from '@app/models/user.model';
import {Study} from '@app/models/study.model';
import {Bitstream} from '@app/models/bitstream.model';

export class StudyZipRequest {
	public createdAt: string;
	public id: number;
	public study: Study;
	public user: User;

	public bitstreams: Bitstream[] = [];
}
