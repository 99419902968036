import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Account} from '@app/models/account.model';
import {Bitstream} from '@app/models/bitstream.model';
import {BitstreamMask} from '@app/models/bitstream-mask.model';
import {BitstreamProduct} from '@app/models/bitstream-product.model';
import {BitstreamTag} from '@app/models/bitstream-tag.model';
import {Measurement} from '@app/models/measurement.model';
import {MeasurementBitstream} from '@app/models/measurement-bitstream.model';
import {Modality} from '@app/models/modality.model';
import {Pretreatment} from '@app/models/pretreatment.model';
import {Product} from '@app/models/product.model';
import {Study} from '@app/models/study.model';
import {StudyAttachment} from '@app/models/study-attachment.model';
import {StudyLog} from '@app/models/study-log';
import {StudyPatient} from '@app/models/study-patient.model';
import {StudyUser} from '@app/models/study-user.model';
import {View} from '@app/models/view.model';
import {Visit} from '@app/models/visit.model';
import {AccountUtil} from '@app/utils/account.util';

import {Observable} from 'rxjs';

@Injectable()
export class StudyService {
	constructor(private client: HttpClient) {}

	public get(): Observable<Study[]> {
		return this.client
			.get<Study[]>('/studies')
			.pipe(
				map((response) =>
					response.map((study) => Object.assign(new Study(), study))
				)
			);
	}

	public getAll(): Observable<Study[]> {
		return this.client
			.get<Study[]>('/studies?archived=null')
			.pipe(
				map((response) =>
					response.map((study) => Object.assign(new Study(), study))
				)
			);
	}

	public getAccountsById(id: number): Observable<Account[]> {
		return this.client
			.get<Account[]>('/studies/' + id + '/accounts')
			.pipe(
				map((response) =>
					response.map((account) => AccountUtil.assign(account))
				)
			);
	}

	public getBitstreamsById(id: number): Observable<Bitstream[]> {
		return this.client
			.get<Bitstream[]>('/studies/' + id + '/bitstreams')
			.pipe(
				map((response) =>
					response.map((bitstream) => Object.assign(new Bitstream(), bitstream))
				)
			);
	}

	public getBitstreamMasksById(id: number): Observable<BitstreamMask[]> {
		return this.client
			.get<BitstreamMask[]>('/studies/' + id + '/bitstream-masks')
			.pipe(
				map((response) =>
					response.map((bitstreamMask) =>
						Object.assign(new BitstreamMask(), bitstreamMask)
					)
				)
			);
	}

	public getBitstreamProductsById(id: number): Observable<BitstreamProduct[]> {
		return this.client
			.get<BitstreamProduct[]>('/studies/' + id + '/bitstream-products')
			.pipe(
				map((response) =>
					response.map((bitstreamProduct) =>
						Object.assign(new BitstreamProduct(), bitstreamProduct)
					)
				)
			);
	}

	public getBitstreamTagsById(id: number): Observable<BitstreamTag[]> {
		return this.client
			.get<BitstreamTag[]>('/studies/' + id + '/bitstream-tags')
			.pipe(
				map((response) =>
					response.map((bitstreamTag) =>
						Object.assign(new BitstreamTag(), bitstreamTag)
					)
				)
			);
	}

	public getById(id: number): Observable<Study> {
		return this.client
			.get<Study>('/studies/' + id)
			.pipe(map((response) => Object.assign(new Study(), response)));
	}

	public getStudyLogsById(id: number): Observable<StudyLog[]> {
		return this.client
			.get<StudyLog[]>('/studies/' + id + '/study-logs')
			.pipe(
				map((response) =>
					response.map((studyLog) => Object.assign(new StudyLog(), studyLog))
				)
			);
	}

	public getMeasurementsById(id: number): Observable<Measurement[]> {
		return this.client
			.get<Measurement[]>('/studies/' + id + '/measurements')
			.pipe(
				map((response) =>
					response.map((measurement) =>
						Object.assign(new Measurement(), measurement)
					)
				)
			);
	}

	public getMeasurementBitstreamsById(id: number): Observable<Modality[]> {
		return this.client
			.get<Modality[]>('/studies/' + id + '/measurement-bitstreams')
			.pipe(
				map((response) =>
					response.map((measurementBitstream) =>
						Object.assign(new MeasurementBitstream(), measurementBitstream)
					)
				)
			);
	}

	public getModalitiesById(id: number): Observable<Modality[]> {
		return this.client
			.get<Modality[]>('/studies/' + id + '/modalities')
			.pipe(
				map((response) =>
					response.map((modality) => Object.assign(new Modality(), modality))
				)
			);
	}

	public getPretreatmentsById(id: number): Observable<Pretreatment[]> {
		return this.client
			.get<Pretreatment[]>('/studies/' + id + '/pretreatments')
			.pipe(
				map((response) =>
					response.map((pretreatment) =>
						Object.assign(new Pretreatment(), pretreatment)
					)
				)
			);
	}

	public getProductsById(id: number): Observable<Product[]> {
		return this.client
			.get<Product[]>('/studies/' + id + '/products')
			.pipe(
				map((response) =>
					response.map((product) => Object.assign(new Product(), product))
				)
			);
	}

	public getStudyAttachmentsById(id: number): Observable<StudyAttachment[]> {
		return this.client
			.get<StudyAttachment[]>('/studies/' + id + '/study-attachments')
			.pipe(
				map((response) =>
					response.map((studyAttachment) =>
						Object.assign(new StudyAttachment(), studyAttachment)
					)
				)
			);
	}

	public getStudyPatientsById(id: number): Observable<StudyPatient[]> {
		return this.client
			.get<StudyPatient[]>('/studies/' + id + '/study-patients')
			.pipe(
				map((response) =>
					response.map((studyPatient) =>
						Object.assign(new StudyPatient(), studyPatient)
					)
				)
			);
	}

	public getStudyUsersById(id: number): Observable<StudyUser[]> {
		return this.client
			.get<StudyUser[]>('/studies/' + id + '/study-users')
			.pipe(
				map((response) =>
					response.map((studyUser) => Object.assign(new StudyUser(), studyUser))
				)
			);
	}

	public getViewsById(id: number): Observable<View[]> {
		return this.client
			.get<View[]>('/studies/' + id + '/views')
			.pipe(
				map((response) =>
					response.map((view) => Object.assign(new View(), view))
				)
			);
	}

	public getVisitsById(id: number): Observable<Visit[]> {
		return this.client
			.get<Visit[]>('/studies/' + id + '/visits')
			.pipe(
				map((response) =>
					response.map((visit) => Object.assign(new Visit(), visit))
				)
			);
	}

	public post(study: Study): Observable<Study> {
		return this.client
			.post<Study>('/studies', {
				connected: study.connected,
				modalities: study.modalities.map((modality) =>
					Object.assign({}, {id: modality.id})
				),
				name: study.name,
				views: study.views.map((view) => Object.assign({}, {id: view.id})),
				visits: study.visits.map((visit) =>
					Object.assign({}, {name: visit.name})
				),
				system: {
					id: study.system.id,
				},
			})
			.pipe(map((response) => Object.assign(new Study(), response)));
	}

	public put(study: Study): Observable<Study> {
		return this.client
			.put<Study>('/studies/' + study.id, {
				archived: study.archived,
				cro: study.cro,
				imported: study.imported,
				name: study.name,
				sponsor: study.sponsor,
				status: study.status,
			})
			.pipe(map((response) => Object.assign(new Study(), response)));
	}
}
