import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Modality} from '@app/models/modality.model';
import {System} from '@app/models/system.model';
import {View} from '@app/models/view.model';
import {Observable} from 'rxjs';

@Injectable()
export class SystemService {
	constructor(private client: HttpClient) {}

	public get(): Observable<System[]> {
		return this.client
			.get<System[]>('/systems')
			.pipe(
				map((response) =>
					response.map((system) => Object.assign(new System(), system))
				)
			);
	}

	public getById(id: number): Observable<System> {
		return this.client
			.get<System>('/systems/' + id)
			.pipe(map((response) => Object.assign(new System(), response)));
	}

	public getModalitiesById(id: number): Observable<Modality[]> {
		return this.client
			.get<Modality[]>('/systems/' + id + '/modalities')
			.pipe(
				map((response) =>
					response.map((modality) => Object.assign(new Modality(), modality))
				)
			);
	}

	public getViewsById(id: number): Observable<View[]> {
		return this.client
			.get<View[]>('/systems/' + id + '/views')
			.pipe(
				map((response) =>
					response.map((view) => Object.assign(new View(), view))
				)
			);
	}
}
