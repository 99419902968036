import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core';

import {Account} from '@app/models/account.model';
import {StudyLog} from '@app/models/study-log';

import {StudyLogGroup} from '@app/forms/log.group';

import * as XLSX from 'xlsx';

type AOA = any[][];

@Component({
	selector: 'app-study-log',
	templateUrl: './study-log.component.html',
	styleUrls: ['./study-log.component.scss'],
})
export class StudyLogComponent implements OnInit, OnChanges {
	@Input() private account: Account;
	@Input() public studyLogs: StudyLog[] = [];
	@Input() public accountType: string;
	public actualstudyLogs: StudyLog[] = [];
	public chronologicSort = false;
	public form: StudyLogGroup;
	public itemsPerPage = 15;
	public needle = '';
	public page = 1;

	public data: AOA = [
		[1, 2],
		[3, 4],
	];

	constructor() {}

	ngOnInit() {
		this.form = new StudyLogGroup();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.studyLogs.currentValue.length > 0) {
			this.toggleSortLogsByDate(true);
		}
	}

	public export(): void {
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		const lines: any[] = [];

		lines.push(['Study name', 'Study id', 'Action', 'Action date']);

		this.studyLogs.forEach((studyLog) => {
			if (studyLog.study) {
				lines.push([
					studyLog.study.name,
					studyLog.study.id,
					studyLog.action,
					studyLog.createdAt,
				]);
			}
		});

		const name = (this.accountType = 'user'
			? this.account.username
			: 'Device' + this.account.id);
		const title = name + ' logs';

		XLSX.utils.book_append_sheet(
			wb,
			XLSX.utils.aoa_to_sheet(lines),
			title.substr(0, 31)
		);
		XLSX.writeFile(wb, 'study_logs_' + name + '.xlsx');
	}

	public filter(): void {
		if (this.form.action.value !== '') {
			if (this.form.from.value !== '' && this.form.to.value !== '') {
				this.actualstudyLogs = this.studyLogs.filter(
					(actualStudyLog) =>
						new Date(actualStudyLog.createdAt).getTime() >=
							new Date(this.form.from.value).getTime() &&
						new Date(actualStudyLog.createdAt).getTime() <=
							new Date(this.form.to.value).getTime() &&
						actualStudyLog.action === this.form.action.value
				);
			}
		} else {
			if (this.form.from.value !== '' && this.form.to.value !== '') {
				this.actualstudyLogs = this.studyLogs.filter(
					(actualStudyLog) =>
						new Date(actualStudyLog.createdAt).getTime() >=
							new Date(this.form.from.value).getTime() &&
						new Date(actualStudyLog.createdAt).getTime() <=
							new Date(this.form.to.value).getTime()
				);
			}
		}
	}

	public toggleSortLogsByDate(init = false): void {
		if (!init) {
			this.chronologicSort = !this.chronologicSort;
		}

		if (this.chronologicSort) {
			this.actualstudyLogs = [];
			this.actualstudyLogs.push(
				...this.studyLogs.sort(
					(a, b) =>
						new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
				)
			);
		} else {
			this.actualstudyLogs = [];
			this.actualstudyLogs.push(
				...this.studyLogs.sort(
					(a, b) =>
						new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
				)
			);
		}
	}

	public reset(): void {
		this.chronologicSort = true;
		this.needle = '';
		this.toggleSortLogsByDate();
	}
}
