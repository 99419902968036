import {Account} from '@app/models/account.model';
import {Bitstream} from '@app/models/bitstream.model';
import {Device} from '@app/models/device.model';
import {Measurement} from '@app/models/measurement.model';
import {User} from '@app/models/user.model';

export class MeasurementBitstream {
	public acquisitionAt: string;
	public bitstream: Bitstream;
	public id: number;
	public measurement: Measurement;
	public rank: number;
	public uploadBy: Account | Device | User;
	public uploadAt: string;
}
