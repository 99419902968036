<form [formGroup]="form" (submit)="filter()">
  <div class="form-group">
    <label for="from">Connection date</label>
    <div class="row">
      <div class="col-xs-6">
        <input type="date" class="form-control" id="from" formControlName="from">
      </div>

      <div class="col-xs-6">
        <input type="date" class="form-control" formControlName="to">
      </div>
    </div>
  </div>

  <div class="form-group">
    <div class="text-center">
      <button type="submit" class="btn btn-default">Search</button>
      <button type="button" class="btn btn-danger" (click)="reset()">Reset</button>
    </div>
    <button type="button" class="btn btn-primary pull-right" (click)="export()">Export</button>
  </div>
</form>

<ng-container [ngSwitch]="actualAccountSessions.length > 0">
  <ng-container *ngSwitchCase="true">
    <table class="table table-striped">
      <thead>
      <tr class="text-center">
        <th [ngSwitch]="accountType">
          <span *ngSwitchCase="'user'">User</span>
          <span *ngSwitchCase="'device'">Device</span>
        </th>
        <th>
          Connection date
          <span style="cursor: pointer; margin-left: 2em"
                (click)="toggleSortLogsByDate()"
                [ngSwitch]="chronologicSort">
                    <sub *ngSwitchCase="true" class='glyphicon glyphicon-triangle-bottom' aria-hidden='true'></sub>
                    <sup *ngSwitchCase="false" class='glyphicon glyphicon-triangle-top' aria-hidden='true'></sup>
                </span>
        </th>
        <th>Disconnection date</th>
        <th>
          Session length
          <span style="cursor: pointer; margin-left: 2em" [ngSwitch]="sessionLengthSort">
                    <sub *ngSwitchCase="'asc'" (click)="toggleSortLogsBySessionLength('desc') "
                         class='glyphicon glyphicon-triangle-bottom' aria-hidden='true'></sub>
                    <sup *ngSwitchCase="'desc'" (click)="toggleSortLogsBySessionLength('asc')"
                         class='glyphicon glyphicon-triangle-top' aria-hidden='true'></sup>
                </span>
        </th>
        <th>Ip address</th>
      </tr>
      </thead>

      <tbody>
      <tr *ngFor="let actualAccountSession of actualAccountSessions | pagination : page : itemsPerPage">
        <td [ngSwitch]="accountType">
          <span *ngSwitchCase="'user'">
            {{actualAccountSession.account.firstName}} {{actualAccountSession.account.lastName}}
          </span>
          <span *ngSwitchCase="'device'">
            {{actualAccountSession.account.system.name}} {{actualAccountSession.account.serial}}
          </span>
        </td>
        <td>{{actualAccountSession.connectionDate | date:'yyyy/MM/dd HH:mm:ss'}}</td>
        <td [ngSwitch]="actualAccountSession.disconnectionDate !== null">
            <span *ngSwitchCase="true">
                {{actualAccountSession.disconnectionDate | date:'yyyy/MM/dd HH:mm:ss'}}
            </span>
          <span *ngSwitchCase="false">
                n/a
            </span>
        </td>
        <td [ngSwitch]="actualAccountSession.sessionLength !== null || actualAccountSession.sessionLength !== undefined">
          <ng-container *ngSwitchCase="true"
                        [ngSwitch]="actualAccountSession.sessionLength / 1000 < 3600 && actualAccountSession.sessionLength !== 0">
            <span *ngSwitchCase="true">{{actualAccountSession.sessionLength | date:'mm:ss'}}</span>
            <span *ngSwitchCase="false">n/a</span>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <span>n/a</span>
          </ng-container>
        </td>
        <td>
          {{actualAccountSession.ip}}
        </td>
      </tr>
      </tbody>
    </table>
    <div class="text-center">
      <pagination class="pagination-sm"
                  [boundaryLinks]="true"
                  [totalItems]="actualAccountSessions.length"
                  [itemsPerPage]="itemsPerPage"
                  [rotate]="true" [maxSize]="10"
                  [(ngModel)]="page" previousText="&lsaquo;"
                  nextText="&rsaquo;" firstText="&laquo;"
                  lastText="&raquo;"></pagination>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="false">
    <div class="text-center" style="margin-top: 10%; margin-bottom: 10%">
      <em>No logs found</em>
    </div>
  </ng-container>
</ng-container>
