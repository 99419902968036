import {Injectable} from '@angular/core';
import {AuthToken} from '@app/models/auth-token.model';
import {User} from '@app/models/user.model';
import {environment} from '@env/environment';

@Injectable()
export class StorageService {
	private authToken: AuthToken;

	constructor() {
		const authToken = localStorage.getItem(environment.cookie.authToken);

		if (authToken) {
			this.authToken = Object.assign(new AuthToken(), JSON.parse(authToken));
		}
	}

	public getEncodedToken(): string {
		return encodeURIComponent(this.authToken.value);
	}

	public setAuthToken(authToken: AuthToken) {
		this.authToken = authToken;
		localStorage.setItem(
			environment.cookie.authToken,
			JSON.stringify(authToken)
		);
	}

	public getAuthToken(): AuthToken {
		return this.authToken;
	}

	public getFullName(): string {
		return this.authToken.user.firstName + ' ' + this.authToken.user.lastName;
	}

	public getToken(): string {
		return this.authToken ? this.authToken.value : '';
	}

	public getUser(): User {
		return this.authToken.user;
	}

	public isAdmin(): boolean {
		return (this.authToken.user.level & 1) === 1;
	}

	public isDemo(): boolean {
		return this.authToken.user.demo === true;
	}

	public isQuoteMaker(): boolean {
		return (this.authToken.user.level & 8) === 8;
	}

	public isStudyDesigner(): boolean {
		return (this.authToken.user.level & 2) === 2;
	}

	public isTechnician(): boolean {
		return (this.authToken.user.level & 16) === 16;
	}
}
