import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {Document} from '@app/models/document.model';
import {Software} from '@app/models/software.model';
import {Tag} from '@app/models/tag.model';

import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-document-creation-modal',
	templateUrl: './document-creation-modal.component.html',
})
export class DocumentCreationModalComponent {
	@Output() onSubmit = new EventEmitter<Document>();

	public form: FormGroup;
	public softwares: Software[];
	public tags: Tag[];

	constructor(private formBuilder: FormBuilder, public modal: BsModalRef) {
		this.form = formBuilder.group({
			description: ['', Validators.maxLength(255)],
			name: [ '', [
					Validators.required,
					Validators.minLength(1),
					Validators.maxLength(64),
				],
			],
			software: [null],
			tag: [null, Validators.required],
		});
	}

	public submit(): void {
		const formValues = this.form.getRawValue();
		const document = new Document();
		document.archived = false;
		document.name = formValues.name;
		document.description = formValues.description;
		const tag = new Tag();
		tag.id = formValues.tag;
		document.tag = tag;
		if (formValues.software) {
			const software = new Software();
			software.id = formValues.software;
			document.software = software;
		}
		this.onSubmit.emit(document);
		this.modal.hide();
	}
}
