import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Gender} from '@app/models/gender.model';
import {Observable} from 'rxjs';

@Injectable()
export class GenderService {
	constructor(private client: HttpClient) {}

	public get(): Observable<Gender[]> {
		return this.client
			.get<Gender[]>('/genders')
			.pipe(
				map((response) =>
					response.map((gender) => Object.assign(new Gender(), gender))
				)
			);
	}
}
