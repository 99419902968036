import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PasswordRequest} from '@app/models/password-request.model';
import {Observable} from 'rxjs';

@Injectable()
export class PasswordRequestService {
	constructor(private client: HttpClient) {}

	public put(passwordRequest: PasswordRequest): Observable<PasswordRequest> {
		return this.client
			.put<PasswordRequest>('/password-requests/' + passwordRequest.token, {
				password: passwordRequest.password,
			})
			.pipe(
				map((passwordRequest) =>
					Object.assign(new PasswordRequest(), passwordRequest)
				)
			);
	}

	public post(passwordRequest: PasswordRequest): Observable<PasswordRequest> {
		return this.client
			.post<PasswordRequest>('/password-requests', {
				email: passwordRequest.email,
			})
			.pipe(
				map((passwordRequest) =>
					Object.assign(new PasswordRequest(), passwordRequest)
				)
			);
	}
}
