import {Component, OnInit} from '@angular/core';
import {Modality} from '@app/models/modality.model';
import {Study} from '@app/models/study.model';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
	templateUrl: 'index.component.html',
})
export class AddModalityModalComponent implements OnInit {
	public modalities: Modality[] = [];
	public study: Study;

	constructor(public modal: BsModalRef) {}

	public ngOnInit(): void {
		this.modalities = this.study.system.modalities.filter(
			(modality) =>
				!this.study.modalities.some((item) => item.id === modality.id)
		);
	}
}
