<div class="modal-header" [ngSwitch]="unCancelable">
	<ng-container *ngSwitchCase="false">
		<h4 class="modal-title pull-left">Are you sure?</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="modal.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</ng-container>
	<ng-container *ngSwitchCase="true">
		<h4 class="modal-title pull-left">Information</h4>
	</ng-container>
</div>

<div class="clearfix modal-body">
	<div [innerHTML]="message"></div>
	<div *ngIf="!unCancelable">Are you sure you want to proceed?</div>
</div>

<div class="modal-footer">
	<button type="button" class="btn btn-secondary" *ngIf="!unCancelable" (click)="modal.hide()">Cancel</button>
	<button type="button" class="btn" [ngClass]="unCancelable ? 'btn-success' : 'btn-danger'" (click)="confirm()">Continue</button>
</div>
