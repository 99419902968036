import {Account} from '@app/models/account.model';
import {Device} from '@app/models/device.model';
import {Guest} from '@app/models/guest.model';
import {User} from '@app/models/user.model';

export abstract class AccountUtil {
	public static assign(account: Account): Account {
		switch (account.type) {
			case 0:
				return Object.assign(new Device(), account);
			case 1:
				return Object.assign(new User(), account);
			case 2:
				return Object.assign(new Guest(), account);
		}
	}
}
