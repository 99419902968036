import {Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
	templateUrl: 'index.component.html',
})
export class TextModalComponent {
	public text = '';

	constructor(public modal: BsModalRef) {}
}
