import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'livestamp',
})
export class LivestampPipe implements PipeTransform {
	public transform(seconds: number): string {
		if (seconds > 3600) {
			return Math.ceil(seconds / 3600) + ' hours';
		}

		if (seconds > 60) {
			return Math.ceil(seconds / 60) + ' minutes';
		}

		return seconds + ' seconds';
	}
}
