import {User} from '@app/models/user.model';
import {Device} from '@app/models/device.model';

export class UserDevice {
	public createdAt: string;
	public createdBy: User;
	public device: Device;
	public id: number;
	public user: User;
}
