import {Software} from '@app/models/software.model';
import {Tag} from '@app/models/tag.model';

export class Document {
	public id: number;
	public archived: boolean;
	public description: string;
	public name: string;
	public type: string;
	public url: string;
	public version: string;
	public visible: boolean;

	public software: Software;
	public tag: Tag;
}
