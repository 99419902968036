import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {StudyUser} from '@app/models/study-user.model';
import {Observable} from 'rxjs';

@Injectable()
export class StudyUserService {
	constructor(private client: HttpClient) {}

	public get(): Observable<StudyUser[]> {
		return this.client
			.get<StudyUser[]>('/study-users')
			.pipe(
				map((response) =>
					response.map((studyUser) => Object.assign(new StudyUser(), studyUser))
				)
			);
	}

	public post(studyUser: StudyUser): Observable<StudyUser> {
		return this.client
			.post<StudyUser>('/study-users', {
				active: studyUser.active,
				level: studyUser.level,
				study: {
					id: studyUser.study.id,
				},
				user: {
					id: studyUser.user.id,
				},
			})
			.pipe(map((response) => Object.assign(new StudyUser(), response)));
	}

	public put(studyUser: StudyUser): Observable<StudyUser> {
		return this.client
			.put<StudyUser>('/study-users/' + studyUser.id, {
				active: studyUser.active,
				id: studyUser.id,
				level: studyUser.level,
				study: {
					id: studyUser.study.id,
				},
				user: {
					id: studyUser.user.id,
				},
			})
			.pipe(map((response) => Object.assign(new StudyUser(), response)));
	}

	public remove(studyUser: StudyUser): Observable<Response> {
		return this.client.delete<Response>('/study-users/' + studyUser.id);
	}
}
