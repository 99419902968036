<div class="modal-header">
	<button type="button" class="close pull-right" aria-label="Close" (click)="modal.hide()">
		<span aria-hidden="true">&times;</span>
	</button>

	<h4 class="modal-title">Get the StudiesDownloader</h4>
</div>

<div class="modal-body">
	<div class="form-group">
		<ol>
			<li>Download the <a href="https://store.newtone.fr/softwares/StudiesDownloader.zip">software</a></li>
			<li>
				<div class="form-group">Unzip the file anywhere on your computer. The folder should contains the following files.</div>
				<img class="center-block form-group" src="./assets/softwares/studies-downloader/1.png" alt="Step 1" style="max-width: 500px">
			</li>
			<li>Launch StudiesDownloader.exe</li>
			<li>
				<div class="form-group">Connect using the same credentials you use on Newtone’s portal</div>
				<img class="center-block form-group" src="./assets/softwares/studies-downloader/2.png" alt="Step 3" style="max-width: 500px">
			</li>
			<li>
				<div class="form-group">When you are connected you should see the list of the available studies</div>
				<img class="center-block form-group" src="./assets/softwares/studies-downloader/3.png" alt="Step 3" style="max-width: 500px">
			</li>
			<li>
				<div class="form-group">Select the study and click on the select button or double click on the study to open it. Please note that you can filter the studies by taping a word on the search bar at the top of the window</div>
				<img class="center-block form-group" src="./assets/softwares/studies-downloader/4.png" alt="Step 4" style="max-width: 500px">
			</li>
			<li>
				<div class="form-group">Once the study is open you will see all the details of the study</div>
				<img class="center-block form-group" src="./assets/softwares/studies-downloader/5.png" alt="Step 5" style="max-width: 500px">
			</li>
			<li>
				<div class="form-group">Select what you want to download, patients, zones, visits and modalities. You can use the buttons “select all” and “unselect all” to make a quicker selection. Please note that you can filter the patients by using the search bar at the top of the window. Once the selection is done click on download. Select the folder where you want to save the images. The download will begin</div>
				<img class="center-block form-group" src="./assets/softwares/studies-downloader/6.png" alt="Step 6" style="max-width: 500px">
			</li>
		</ol>
	</div>

	<div class="alert alert-info" role="alert">
		<span class="font-bold">Additional information:</span>
		<div class="form-group">
			<ul>
				<li>If a download is stopped in the middle you can restart the download where it stops. To do that select the images again and select the same folder that you have used the first time. The software will detect the images that have been already downloaded and will download only the missing images.</li>
				<li>When the software starts it will check if there is a newer version available. If it is the case you should see the following message</li>
			</ul>
		</div>
		<img class="center-block" src="./assets/softwares/studies-downloader/7.png" alt="Step 7" style="max-width: 500px">
	</div>
</div>
