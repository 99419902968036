import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	ValidationErrors,
	Validators,
} from '@angular/forms';

import {Account} from '@app/models/account.model';
import {Device} from '@app/models/device.model';
import {Guest} from '@app/models/guest.model';
import {User} from '@app/models/user.model';

import {of, Observable} from 'rxjs';

@Component({
	selector: 'app-account-picker',
	templateUrl: 'index.component.html',
})
export class AccountPickerComponent {
	@Input() accounts: Array<Account | Device | Guest | User> = [];
	@Output() onPick = new EventEmitter<Account | Device | Guest | User>();

	public form: FormGroup;

	constructor(formBuilder: FormBuilder) {
		this.form = formBuilder.group({
			needle: ['', [Validators.required, (e) => this.validate(e)]],
		});
	}

	private getAccount(needle: string): Account | Device | Guest | User {
		const username = needle.toLowerCase();
		return this.accounts.find(
			(account) => account.username.toLowerCase() === username
		);
	}

	public getAccountSource(): Observable<
		Array<Account | Device | Guest | User>
	> {
		return of(
			this.accounts.filter((account) => {
				const needle = this.form.getRawValue().needle.toLowerCase();

				switch (account.type) {
					case 0:
						return (
							account.username.toLowerCase().includes(needle) ||
							(<Device>account).serial.toLowerCase().includes(needle) ||
							(<Device>account).system.name.toLowerCase().includes(needle)
						);
					case 1:
						return (
							account.username.toLowerCase().includes(needle) ||
							(<User>account).email.toLowerCase().includes(needle) ||
							(<User>account).firstName.toLowerCase().includes(needle) ||
							(<User>account).lastName.toLowerCase().includes(needle) ||
							(<User>account).company.toLowerCase().includes(needle)
						);
					case 2:
						return (
							account.username.toLowerCase().includes(needle) ||
							account.username.toLowerCase().includes(needle)
						);
				}
			})
		);
	}

	public submit(): void {
		this.onPick.emit(this.getAccount(this.form.getRawValue().needle));
		this.form.patchValue({needle: ''});
	}

	private validate(control: FormControl): ValidationErrors | null {
		return !control.value || this.getAccount(control.value)
			? null
			: {
					username: {
						valid: false,
					},
			  };
	}
}
